import React from 'react';
import Layout from '../../views/layout';
import CategoryListComponent from '../../components/categories/categoryList';


const CategoryListPage = () => {
  return (
    <Layout>
        <CategoryListComponent />
    </Layout>
  )
}

export default CategoryListPage;