import React from 'react';
import Layout from '../../views/layout';
import FormEditSettingComponent from '../../components/settings/formEditSetting';


const EditSettingPage = () => {
  return (
    <Layout>
        <FormEditSettingComponent />
    </Layout>
  )
}

export default EditSettingPage;