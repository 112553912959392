import React, {useState} from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import config from '../../config';

const FormAddUserComponent = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [role, setRole] = useState("customer");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    const saveUser = async(e) => {
        e.preventDefault();
        try {
            await axios.post(config.server.backend + '/users', {
                name: name.toLowerCase(),
                email: email.toLowerCase(),
                password: password,
                confPassword: confPassword,
                role: role
            });
            navigate("/users");
        } catch (error) {
            if(error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

  return (
    <div className="row">
        <div className="col-lg-6 grid-margin stretch-card">
            <div className="card">
            <div className="card-body">
                <h4 className="card-title">Add new member</h4>
                <p className="card-description">Basic form layout</p>

                {msg &&
                    <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{msg}</div>
                }

                <form className="form-add-user" onSubmit={saveUser}>
                    <div className="form-group">
                        <label htmlFor="inputFullName">Full name</label>
                        <input type="text" className="form-control" id='inputFullName' placeholder='Full name' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputEmailAddress">Email address</label>
                        <input type="email" className="form-control" id='inputEmailAddress' placeholder='Email address' value={email} onChange={(e) => setEmail(e.target.value)} />
                                
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputPassword">Password</label>
                        <input type="password" className="form-control" id='inputPassword' autoComplete='off' placeholder='********' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputConfirmPassword">Confirm Password</label>
                        <input type="password" className="form-control" id='inputConfirmPassword' autoComplete='off' placeholder='********' value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
                    </div>
                    {
                        props?.user && props.user.role === 'admin' && (
                            
                        <div className="form-group">
                            <label htmlFor="inputRole">Role</label>
                            <select className='form-select' name='role' id='inputRole' value={role} onChange={(e) => setRole(e.target.value)}>
                                <option value="admin">Admin</option>
                                <option value="agent">Agent</option>
                                <option value="customer">Customer</option>
                            </select>
                        </div>
                        )
                    }
                    <button type="submit" className="btn btn-primary me-2">Submit</button>
                    <Link to={`/users/`} className='btn btn-light'>Cancel</Link>
                </form>
            </div>
            </div>
        </div>
    </div>
    
  )
};

export default FormAddUserComponent;