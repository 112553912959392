import React from "react";
import "../../spinner.css";
import { Oval } from  'react-loader-spinner';

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <Oval
        height={80}
        width={80}
        color="#ffaf00"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#ffffff"
        strokeWidth={5}
        strokeWidthSecondary={5}

        />
    </div>
  );
}