import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/global/login";
import Dashboard from "./pages/dashboard";

import UsersPage from "./pages/userPages/users";
import AddUserPage from "./pages/userPages/addUser";
import EditUserPage from "./pages/userPages/editUser";

import TypesListPage from "./pages/typePages/types";
import AddTypePage from "./pages/typePages/addType";
import EditTypePage from "./pages/typePages/editType";

import SumexListPage from "./pages/sumexPages/sumex";
import AddSumexPage from "./pages/sumexPages/addSumex";
import EditSumexPage from "./pages/sumexPages/editSumex";

import CurrencyListPage from "./pages/currencyPages/currency";

import CategoryListPage from "./pages/categoryPages/category";
import AddCategoryPage from "./pages/categoryPages/addCategory";
import EditCategoryPage from "./pages/categoryPages/editCategory";

import CompanyListPage from "./pages/companyPages/company";
import AddCompanyPage from "./pages/companyPages/addCompany";
import EditCompanyPage from "./pages/companyPages/editCompany";

import VendorsListPage from "./pages/vendorPages/vendor";
import AddVendorPage from "./pages/vendorPages/addVendor";
import EditVendorPage from "./pages/vendorPages/editVendor";

import VendorsTypeListPage from "./pages/vendorTypePages/vendorType";
import AddVendorTypePage from "./pages/vendorTypePages/addVendorType";
import EditVendorTypePage from "./pages/vendorTypePages/editVendorType";

import AccountListPage from "./pages/accountPages/account";

import JournalCreatePage from "./pages/journalPages/createJournal";

import SettingListPage from "./pages/settingPages/setting";
import AddSettingPage from "./pages/settingPages/addSetting";
import EditSettingPage from "./pages/settingPages/editSetting";


function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />       
          <Route path="/dashboard/" element={<Dashboard />} />         
             
          <Route path="/users/" element={<UsersPage />} />         
          <Route path="/users/add/" element={<AddUserPage />} />         
          <Route path="/users/edit/:id" element={<EditUserPage />} />       
             
          <Route path="/types/" element={<TypesListPage />} />            
          <Route path="/types/add/" element={<AddTypePage />} />              
          <Route path="/types/edit/:id" element={<EditTypePage />} />   

          <Route path="/sumex/" element={<SumexListPage />} />         
          <Route path="/sumex/add/" element={<AddSumexPage />} />          
          <Route path="/sumex/edit/:id" element={<EditSumexPage />} />  

          <Route path="/currency/" element={<CurrencyListPage />} />

          <Route path="/category/" element={<CategoryListPage />} />     
          <Route path="/category/add/" element={<AddCategoryPage />} />               
          <Route path="/category/edit/:id" element={<EditCategoryPage />} />   

          <Route path="/company/" element={<CompanyListPage />} />     
          <Route path="/company/add/" element={<AddCompanyPage />} />               
          <Route path="/company/edit/:id" element={<EditCompanyPage />} />    

          <Route path="/vendor/" element={<VendorsListPage />} />     
          <Route path="/vendor/add/" element={<AddVendorPage />} />               
          <Route path="/vendor/edit/:id" element={<EditVendorPage />} />      

          <Route path="/vendor-type/" element={<VendorsTypeListPage />} />     
          <Route path="/vendor-type/add/" element={<AddVendorTypePage />} />               
          <Route path="/vendor-type/edit/:id" element={<EditVendorTypePage />} />  
                        
          <Route path="/account/" element={<AccountListPage />} />  
          
          <Route path="/journal-create/" element={<JournalCreatePage />} /> 

          <Route path="/setting/" element={<SettingListPage />} />     
          <Route path="/setting/add/" element={<AddSettingPage />} />               
          <Route path="/setting/edit/:id" element={<EditSettingPage />} />

        </Routes>
    </BrowserRouter>
  );
}

export default App;
