import React, {useEffect, useState} from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { Link, useParams, useNavigate } from 'react-router-dom';
import config from '../../config';
import LoadingSpinner from "../global/loadingSpinner";
import { Row, Col } from "react-bootstrap";

const FormAddSettingComponent = () => {
    const [listCategories, setListCategories] = useState([]);  
    const [listTypes, setListTypes] = useState([]);  

    // get data vendor type with SWR
    const getListCategories = async(url) => {
        const response = await axios.get( url);
        setListCategories(response.data);
    };  
    useSWR( config.server.backend + '/category' , getListCategories);

    // get data vendor type with SWR
    const getListTypes = async(url) => {
        const response = await axios.get( url);
        setListTypes(response.data);
    };  
    useSWR( config.server.backend + '/type' , getListTypes);


    return (
        <Row>
            <Col>
            {
                console.log(listCategories)
            }
                <h4 className="card-title">Kategori</h4>
                <ul>
                    {
                        listCategories.map(category => {
                            
                            return (
                                <li key={category.id}>{  category.name }</li>
                            )
                        })
                    }
                </ul>

                <h4 className="card-title">Tipe</h4>
                <ul>
                    {
                        listTypes.map(type => {
                            
                            return (
                                <li key={type.id}>{  type.name }</li>
                            )
                        })
                    }
                </ul>
            </Col>
        </Row>        
    )
}

export default FormAddSettingComponent;