import React from 'react';
import Layout from '../../views/layout';
import FormAddVendorComponent from '../../components/vendors/formAddVendor';

const AddVendorPage = () => {
  return (
    <Layout>
        <FormAddVendorComponent/>
    </Layout>
  )
};

export default AddVendorPage;