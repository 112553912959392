import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import config from '../../config';
import * as Utils from '../../features/utils';
import LoadingSpinner from "../global/loadingSpinner";
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';

const FormEditCompanyComponent = (props) => {
    const id = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();
    const [showLogo, setShowLogo] = useState('');
    const [oldLogo, setOldLogo] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [selectDefaultValue, setSelectDefaultValue] = useState('');
    const [name, setName] = useState('');
    const [nib, setNib] = useState('');
    const [npwp, setNpwp] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [membership, setMembership] = useState('');
    const [phone, setPhone] = useState('');
    const [userId, setUserId] = useState({});
    const [address, setAddress] = useState('');
    const [logo, setLogo] = useState('');

    const onChangeLogo = (file) => {
        setLogo(file);
        setShowLogo(file);
    }

    const onChangeEventSelect = (e) => {
        const ObjectByID = Utils.getObjectByProp(customerList, e.value, 'key');
        if(ObjectByID !== undefined) {
            const email = ObjectByID.email;
            const phone = ObjectByID.phone;
            const dataSelectDefault = selectDefaultValue;
            dataSelectDefault.label = ObjectByID.label;
            dataSelectDefault.value = ObjectByID.value;
            dataSelectDefault.key = ObjectByID.key;
            setSelectDefaultValue(dataSelectDefault);
            setEmail(email);
            setPhone(phone);
        }
        setUserId(e.value);
    }

    useEffect(()=> {
        const getCompanyById = async() => {
            try {
                const response = await axios.get(config.server.backend + `/company/${id.id}`);
                const resData = response.data;
                const { name, nib, npwp, email, status, membership, phone, userId, user, address, logo } = resData;
                setUserId(userId);
                setName(name);
                setNib(nib);
                setNpwp(npwp);
                setEmail(email);
                setStatus(status);
                setMembership(membership);
                setPhone(phone);
                setAddress(address);
                setSelectDefaultValue({
                    label: user.name,
                    value: user.id,
                    key: user.id

                });
                if(logo) {
                    setOldLogo( logo);
                }
            } catch (error) {
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
            }
            setIsLoading(false);
        };
        const getCustomerList = async() => {
            try {
                const response = await axios.get( config.server.backend + '/customers' );
                const resData = response.data;
                let objCustomer = [];
                resData.forEach(element => {
                    objCustomer = [...objCustomer,
                        {
                            label: element.name,
                            value: element.id,
                            key: element.id,
                            phone: element.phone,
                            email: element.email
                        }
                    ]
                });
                setCustomerList(objCustomer);
            } catch (error) {
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
            }
            setIsLoading(false);
        }; 
        getCompanyById(); 
        getCustomerList();
   }, [id]);    

    const updateCompany = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        let dataObject = (logo) => {
            let newDataPost = {                              
                name: name,
                nib: nib,
                npwp: npwp,
                email: email,
                status: status,
                membership: membership,
                phone: phone,
                userId: userId,
                address: address
            }
            if(logo !== undefined && logo !== null) newDataPost['logo'] = logo;
            return newDataPost;
        }

        if (logo) {        
            try {      
                await axios.post( 
                    config.server.backend + '/files/uploadimage', 
                    {
                        image: logo,
                        name: name
                    },
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    },
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                ).then(async (response) => {
                    if(response.status === 200) {
                        const imageFileName = response.data.image;
                            await axios.patch( config.server.backend + `/company/${id.id}`, dataObject(imageFileName) ).then(() => {
                                
                                //remove old image on submitting data success
                                axios.delete( config.server.backend + '/files/deleteimage/' + oldLogo );
                                navigate(props?.user && props.user.role === 'admin' ? '/company/':'/dashboard/');
                            })
                            .catch((error) => {
                                setIsLoading(false);
                                if(error.response) {
                                    setMsg(error.response.data.msg);
                                }
                                Utils.scrollToTop();
                                //remove image on submitting data failed
                                axios.delete( config.server.backend + '/files/deleteimage/' + imageFileName );
                            });
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    if(error.response) {
                        setMsg(error.response.data.msg);
                    }
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      });
                })
            
            } catch (error) {
                setIsLoading(false);
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  });
            }
        } else {
            try {                    
                await axios.patch( config.server.backend + `/company/${id.id}`, dataObject()).then(() => {
                    navigate(props?.user && props.user.role === 'admin' ? '/company/':'/dashboard/');
                });
            } catch (error) {
                setIsLoading(false);
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  });
            }
        }
    }; 

    return (
        <div className='row'>
            {msg &&
                <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{msg}</div>
            }
            {
                isLoading && ( <LoadingSpinner /> )
            }
            <div className="col-xl-12 col-xxl-8 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Edit Data Perusahaan</h4>
                        <p className="card-description">Berikut adalah profile dari perusahaan: <strong className='text-primary'>{name}</strong>.</p>
                        <form onSubmit={updateCompany} encType='multipart/form-data'>
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Nama Perusahaan:</label>
                                                <input name='name' type="text" className="form-control" placeholder='Nama perusahaan...' value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="nib">NIB:</label>
                                                <input name='nib' type="text" className="form-control" placeholder='NIB...' value={nib} onChange={(e) => setNib(e.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="npwp">NPWP:</label>
                                                <input name='npwp' type="text" className="form-control" placeholder='NPWP...' value={npwp} onChange={(e) => setNpwp(e.target.value)} />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="npwp">Logo:</label>
                                                <label className="file-label">
                                                    {
                                                        showLogo && (
                                                            <img alt='' src={URL.createObjectURL(showLogo)} /> 
                                                        )
                                                    }
                                                    { oldLogo && !showLogo && (
                                                        <>
                                                            <img alt='' src={ config.server.backend + '/' + oldLogo} />
                                                            <input name='oldLogo' value={oldLogo} type='hidden' />
                                                        </>
                                                    ) }
                                                    <input className="file-input" accept="image/*" type="file" name="logo" placeholder='logo' onChange={(e) => onChangeLogo(e.target.files[0])} />
                                                    <span className="file-cta">
                                                    <span className="file-icon">
                                                        <FontAwesomeIcon icon={faUpload} />
                                                    </span>
                                                    <span className="file-label">
                                                        {(oldLogo || showLogo) && ('Update')} Logo <small><i>(format: *.svg, *.jpg, *.jpeg, *.png)</i></small>
                                                    </span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="status">Status:</label>
                                        {
                                            props?.user && props.user.role === 'admin' ?
                                            (
                                                <input name='status' type="text" className="form-control" placeholder='Status...'  value={status} onChange={(e) => setStatus(e.target.value)} />
                                            ) : (
                                                <p className='bg-body-secondary text-uppercase border rounded-1 px-3 py-1 fw-bold'>{status}</p>
                                            )
                                        }                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="membership">Tipe Keanggotaan:</label>
                                        {
                                            props?.user && props.user.role === 'admin' ?
                                            (
                                                <input name='membership' type="text" className="form-control" placeholder='Keanggotaan...' value={membership} onChange={(e) => setMembership(e.target.value)} />
                                            ) : (
                                                <p className='bg-body-secondary text-uppercase border rounded-1 px-3 py-1 fw-bold'>{membership}</p>
                                            )
                                        }    
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="form-group">
                                        <label htmlFor="userId">Supervisor:</label> 
                                        {
                                            selectDefaultValue && customerList && (
                                                props?.user && props.user.role === 'admin' ? 
                                                <Select
                                                    defaultValue= {selectDefaultValue}
                                                    options = {customerList}
                                                    placeholder='Pilih customer...'
                                                    name='userId'
                                                    onChange={(e) => {onChangeEventSelect(e)}}
                                                    className='select-custom'
                                                    isDisabled={props?.user && props.user.role !== 'admin'}
                                                    styles={{
                                                        singleValue: (base) => ({
                                                            ...base,
                                                            display: "flex",
                                                            alignItems: "center"
                                                        })
                                                    }}
                                                />  
                                                : 
                                                <p className='bg-body-secondary text-uppercase border rounded-1 px-3 py-1 fw-bold'>{selectDefaultValue.label}</p>
                                            )
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email Perusahaan:</label>
                                        <input name='email' type="text" className="form-control" placeholder='Email...' value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">No. Telepon:</label>
                                        <input name='phone' type="text" className="form-control" placeholder='Telepon...' value={phone || ''} onChange={(e) => setPhone(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address">Alamat:</label>
                                        <ReactQuill name="address" theme="snow" value={address} onChange={(e)=> setAddress(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <button disabled={msg} type="submit" className="btn btn-primary me-2">Update</button>
                                <Link to={props?.user && props.user.role === 'admin' ? '/company/':'/dashboard/'} className="btn btn-light">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default FormEditCompanyComponent;