import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LogOut, reset } from '../../features/authSlice';
import { currentTime } from '../../features/utils';
import AvatarImage from '../../components/global/avatarImage';
import { NavLink } from 'react-bootstrap';
import config from '../../config';

const Navbar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () =>{
    dispatch(LogOut());
    dispatch(reset());
    navigate('/');
  };

  return (
    <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
      <div className="me-3">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-bs-toggle="minimize">
          <span className="icon-menu"></span>
        </button>
      </div>
      <div>
        <NavLink className="navbar-brand brand-logo" to={"/dashboard/"}>
          <img src={config.server.backend +"/1706267809522-926927595.png"} alt="logo" />
        </NavLink>
        <NavLink className='navbar-brand brand-logo-mini' to={"/dashboard/"}>
          <img src={config.server.backend +"/1706267809522-926927595.png"} alt="logo" />
        </NavLink>
        <a className="navbar-brand brand-logo-mini" href="../index.html">
        </a>
      </div>
    </div>
    <div className="navbar-menu-wrapper d-flex align-items-top">
      <ul className="navbar-nav">
        <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
          <h1 className="welcome-text">Selamat {currentTime()}... <br /><span className="text-black fw-bold text-capitalize">{props?.user && props?.user.name}</span></h1>
          {/* <h3 className="welcome-sub-text">Your performance summary this week </h3> */}
        </li>
      </ul>
      <ul className="navbar-nav ms-auto">
        <li className="nav-item dropdown d-none d-lg-block">
          <a className="nav-link dropdown-bordered dropdown-toggle dropdown-toggle-split" id="messageDropdown" href="/"
            data-bs-toggle="dropdown" aria-expanded="false"> Select Category </a>
          <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
            aria-labelledby="messageDropdown">
            <a className="dropdown-item py-3" href='/'>
              <p className="mb-0 font-weight-medium float-left">Select category</p>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item preview-item" href='/'>
              <div className="preview-item-content flex-grow py-2">
                <p className="preview-subject ellipsis font-weight-medium text-dark">Bootstrap Bundle </p>
                <p className="fw-light small-text mb-0">This is a Bundle featuring 16 unique dashboards</p>
              </div>
            </a>
            <a className="dropdown-item preview-item" href='/'>
              <div className="preview-item-content flex-grow py-2">
                <p className="preview-subject ellipsis font-weight-medium text-dark">Angular Bundle</p>
                <p className="fw-light small-text mb-0">Everything you'll ever need for your Angular projects</p>
              </div>
            </a>
            <a className="dropdown-item preview-item" href='/'>
              <div className="preview-item-content flex-grow py-2">
                <p className="preview-subject ellipsis font-weight-medium text-dark">VUE Bundle</p>
                <p className="fw-light small-text mb-0">Bundle of 6 Premium Vue Admin Dashboard</p>
              </div>
            </a>
            <a className="dropdown-item preview-item" href='/'>
              <div className="preview-item-content flex-grow py-2">
                <p className="preview-subject ellipsis font-weight-medium text-dark">React Bundle</p>
                <p className="fw-light small-text mb-0">Bundle of 8 Premium React Admin Dashboard</p>
              </div>
            </a>
          </div>
        </li>
        <li className="nav-item d-none d-lg-block">
          <div id="datepicker-popup" className="input-group date datepicker navbar-date-picker">
            <span className="input-group-addon input-group-prepend border-right">
              <span className="icon-calendar input-group-text calendar-icon"></span>
            </span>
            <input type="text" name='datepicker-input' className="form-control" />
          </div>
        </li>
        <li className="nav-item">
          <form className="search-form" action="#">
            <i className="icon-search"></i>
            <input type="search" name='search' className="form-control" placeholder="Search Here" title="Search here" />
          </form>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link count-indicator" id="notificationDropdown" href="/" data-bs-toggle="dropdown">
            <i className="icon-bell"></i>
            <span className="count"></span>
          </a>
          <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
            aria-labelledby="notificationDropdown">
            <a className="dropdown-item py-3 border-bottom" href='/'>
              <p className="mb-0 font-weight-medium float-left">You have 4 new notifications </p>
              <span className="badge badge-pill badge-primary float-right">View all</span>
            </a>
            <a className="dropdown-item preview-item py-3" href='/'>
              <div className="preview-thumbnail">
                <i className="mdi mdi-alert m-auto text-primary"></i>
              </div>
              <div className="preview-item-content">
                <h6 className="preview-subject fw-normal text-dark mb-1">Application Error</h6>
                <p className="fw-light small-text mb-0"> Just now </p>
              </div>
            </a>
            <a className="dropdown-item preview-item py-3" href='/'>
              <div className="preview-thumbnail">
                <i className="mdi mdi-settings m-auto text-primary"></i>
              </div>
              <div className="preview-item-content">
                <h6 className="preview-subject fw-normal text-dark mb-1">Settings</h6>
                <p className="fw-light small-text mb-0"> Private message </p>
              </div>
            </a>
            <a className="dropdown-item preview-item py-3" href='/'>
              <div className="preview-thumbnail">
                <i className="mdi mdi-airballoon m-auto text-primary"></i>
              </div>
              <div className="preview-item-content">
                <h6 className="preview-subject fw-normal text-dark mb-1">New user registration</h6>
                <p className="fw-light small-text mb-0"> 2 days ago </p>
              </div>
            </a>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link count-indicator" id="countDropdown" href="/" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="icon-mail icon-lg"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
            aria-labelledby="countDropdown">
            <a className="dropdown-item py-3" href='/'>
              <p className="mb-0 font-weight-medium float-left">You have 7 unread mails </p>
              <span className="badge badge-pill badge-primary float-right">View all</span>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item preview-item" href='/'>
              <div className="preview-thumbnail">
              
                { 
                  props?.user && props?.user.email && 
                    (AvatarImage(props.user.email, "24", false, "img-sm profile-pic rounded-circle"))
                }

              </div>
              <div className="preview-item-content flex-grow py-2">
                <p className="preview-subject ellipsis font-weight-medium text-dark">Marian Garner </p>
                <p className="fw-light small-text mb-0"> The meeting is cancelled </p>
              </div>
            </a>
            <a className="dropdown-item preview-item" href='/'>
              <div className="preview-thumbnail">
              
              { 
                props?.user && props?.user.email && 
                  (AvatarImage(props.user.email, "24", false, "img-sm profile-pic rounded-circle"))
              }

            </div>
              <div className="preview-item-content flex-grow py-2">
                <p className="preview-subject ellipsis font-weight-medium text-dark">David Grey </p>
                <p className="fw-light small-text mb-0"> The meeting is cancelled </p>
              </div>
            </a>
            <a className="dropdown-item preview-item" href='/'>
              <div className="preview-thumbnail">
                
              
              { 
                  props?.user && props?.user.email && 
                    (AvatarImage(props.user.email, "24", false, "img-sm profile-pic rounded-circle"))
                }

              
              </div>
              <div className="preview-item-content flex-grow py-2">
                <p className="preview-subject ellipsis font-weight-medium text-dark">Travis Jenkins </p>
                <p className="fw-light small-text mb-0"> The meeting is cancelled </p>
              </div>
            </a>
          </div>
        </li>
        <li className="nav-item dropdown d-none d-lg-block user-dropdown">
          <a className="nav-link" id="UserDropdown" href="/" data-bs-toggle="dropdown" aria-expanded="false">
            { props?.user && props?.user.email && (AvatarImage(props.user.email, "24", false, "img-xs rounded-circle"))}
            </a>
          <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
            <div className="dropdown-header text-center">
              { props?.user && props?.user.email && (AvatarImage(props.user.email, "24", false, "img-md rounded-circle"))}
              <p className="mb-1 mt-3 font-weight-semibold text-capitalize">{ props?.user && props?.user.name && (props.user.name)}</p>
              <p className="fw-light text-muted mb-0 fw-bold text-lowercase">{ props?.user && props?.user.role && (props.user.role)}</p>
              <p className="fw-light text-muted mb-0 text-lowercase">{ props?.user && props?.user.email && (props.user.email)}</p>
            </div>
            <a className="dropdown-item" href='/'><i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i> My
              Profile <span className="badge badge-pill badge-danger">1</span></a>
            <a className="dropdown-item" href='/'><i className="dropdown-item-icon mdi mdi-message-text-outline text-primary me-2"></i>
              Messages</a>
            <a className="dropdown-item" href='/'><i className="dropdown-item-icon mdi mdi-calendar-check-outline text-primary me-2"></i>
              Activity</a>
            <a className="dropdown-item" href='/'><i className="dropdown-item-icon mdi mdi-help-circle-outline text-primary me-2"></i>
              FAQ</a>
            <button onClick={logout} className="dropdown-item">
              <i className="dropdown-item-icon mdi mdi-power text-primary me-2"></i>Sign Out
              </button>
          </div>
        </li>
      </ul>
      <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
        data-bs-toggle="offcanvas">
        <span className="mdi mdi-menu"></span>
      </button>
    </div>
  </nav>
  )
}

export default Navbar