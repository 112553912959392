import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate  } from 'react-router-dom';
import useSWR from 'swr';
import config from '../../config';
import LoadingSpinner from "../global/loadingSpinner";
import * as Utils from '../../features/utils';

const FormEditVendorTypeComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [code, setCode] = useState("");   
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(()=> {
        const getVendorTypeId = async() => {
            try {
                const response = await axios.get(config.server.backend + `/vendor-type/${id}`);
                setName(response.data.name);
                setCode(response.data.code);
            } catch (error) {
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
            }
        }
        getVendorTypeId();
   }, [id]);    

    const updateVendorType = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        try {                    
            await axios.patch( config.server.backend + `/vendor-type/${id}`, {
                name: name,
                code: code
            }).then(() => {
                navigate('/vendor-type');
            });
        } catch (error) {
            setIsLoading(false);
            if(error.response) {
                setMsg(error.response.data.msg);
            }
            Utils.scrollToTop();
        }
    }; 
    
    const getVendorType = async() => {
        const response = await axios.get( config.server.backend + '/vendor-type' );
        return response.data;
    };  

    // realtime data pakai SWR
    const {data} = useSWR('vendor-typeData', getVendorType);
    if(!data) return <span>Data is empty...</span>;

    console.log("Datanya:", data);

    return (
        <div className='row'>
            
            {
                isLoading && ( <LoadingSpinner /> )
            }
            <div className="col-lg-6 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Edit Tipe Vendor</h4>
                        <p className="card-description">Edit the vendor-type data here below.</p>

                        {msg &&
                            <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{msg}</div>
                        }
                        <form onSubmit={updateVendorType}>
                            <div className="form-group">
                                <label htmlFor="name">Nama Tipe Vendor</label>
                                <input name='name' type="text" className="form-control" placeholder='Nama tipe vendor' value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="code">Code Tipe Vendor</label>
                                <input name='code' type="text" className="form-control" placeholder='Kode tipe vendor' value={code} onChange={(e) => setCode((e.target.value).toUpperCase())} />
                                <small className='form-text fs-xs'>Jumlah kode minimal 3 huruf:</small>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary me-2">Submit</button>
                                <Link to={`/vendor-type/`} className="btn btn-light">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default FormEditVendorTypeComponent;