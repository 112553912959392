import md5 from 'md5';
export default function AvatarImage (email, imageSize, isNotRounded, customClassName)  {
    if(!email) return '';
    
    const md5Email = md5(email);
    const size = {
        "16": "is-16x16",
        "24": "is-24x24",
        "32": "is-32x32",
        "48": "is-48x48",
        "64": "is-64x64",
        "98": "is-98x98",
        "128": "is-128x128"
    }
    
    if(!imageSize) {
        imageSize = "24"
    }

    let imageClassName = !isNotRounded ? " is-rounded "  : "";
    imageClassName = imageClassName + size[imageSize];

    return (                
        <figure className={ "image m-0 " + size[imageSize]}>
            <img className={ imageClassName + " " + customClassName } 
            
            alt='' src={'https://gravatar.com/avatar/'+ md5Email } />
        </figure>
    )
} 