import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate  } from 'react-router-dom';
import useSWR from 'swr';
import config from '../../config';
import * as Utils from '../../features/utils';
import LoadingSpinner from "../global/loadingSpinner";
import ReactQuill from 'react-quill';
import Select from "react-select";
import { Row, Col } from "react-bootstrap";

const FormAddVendorComponent = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const [dataCompanies, setDataCompanies] = useState([]);
    const [dataVendorType, setDataVendorType] = useState([]);
    const [detailVendor, setDetailVendor] = useState({
        name: "",
        code: "",
        nib: "",
        npwp: "",
        email: "",
        status: "",
        membership: "",
        phone: "",
        pic: "",
        address: "",
        companyId: "",
        vendorTypeId: []
    });
    
    const onChangeEventName = (value, name) => {  
        const dataDetailVendor = detailVendor;
        dataDetailVendor[name] = value;
        setDetailVendor(dataDetailVendor);
    }

    const saveVendor = async(e) => {
        e.preventDefault();
        console.log(detailVendor);
        setIsLoading(true);
        if(props?.user && props.user.role !== 'admin') {
            detailVendor.companyId = props.user.company.id;
        }
        try {
            await axios.post( config.server.backend + '/vendor/', detailVendor ).then(() => {
                setIsLoading(false);
                navigate('/vendor/');
            }).catch((error) => {
                setIsLoading(false);
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
                Utils.scrollToTop();
            });
        } catch (error) {
            setIsLoading(false);
            if(error.response) {
                setMsg(error.response.data.msg);
            }
            Utils.scrollToTop();
        }
    };

    // get data companies with SWR
    const getDataCompanies = async(url) => {
        const response = await axios.get( url);
        const responseData = response.data;
        setDataCompanies(responseData);
    };  
    useSWR( config.server.backend + '/company' , getDataCompanies);
    
    // get data vendor type with SWR
    const getDataVendorType = async(url) => {
        const response = await axios.get( url);
        const responseData = response.data;
        setDataVendorType(responseData);
    };  
    useSWR( config.server.backend + '/vendor-type' , getDataVendorType);

    return (
        <Row>
            {
                isLoading && ( <LoadingSpinner /> )
            }
            <Col xl={8} xxl={6} className='grid-margin stretch-card'>
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Tambah Vendor Baru</h4>
                        <p className="card-description">Register the new vendor here below.</p>

                        {msg &&
                            <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{msg}</div>
                        }
                        <form onSubmit={saveVendor}>
                            <Row>
                                <Col xl={6}>
                                    {
                                        props?.user && props.user.role === 'admin' && (
                                            <div className="form-group">
                                                <label htmlFor="companyId">Vendor dari perusahaan:</label>
                                                <Select
                                                    options = {
                                                        dataCompanies?.map((company) => (
                                                            {
                                                                value: company.id,
                                                                label: company.name
                                                            }
                                                        ))
                                                    }
                                                    placeholder='Pilih perusahaan...'
                                                    name='companyId'
                                                    onChange={(e) => {onChangeEventName(e.value, 'companyId')}}
                                                    className='select-custom'
                                                    styles={{
                                                        singleValue: (base) => ({
                                                            ...base,
                                                            display: "flex",
                                                            alignItems: "center"
                                                        })
                                                    }}
                                                />  
                                            </div>
                                        )
                                    }
                                    <div className="form-group">
                                        <label htmlFor="vendorType">Tipe Vendor:</label>
                                        <Select
                                            options = {
                                                dataVendorType?.map((vendorType) => (
                                                    {
                                                        value: vendorType.id,
                                                        label: vendorType.name
                                                    }
                                                ))
                                            }
                                            placeholder='Pilih tipe vendor...'
                                            name='vendorTypeId'
                                            onChange={(e) => {onChangeEventName(e, 'vendorTypeId')}}
                                            className='select-custom'
                                            isMulti={true}
                                            styles={{
                                                singleValue: (base) => ({
                                                    ...base,
                                                    display: "flex",
                                                    alignItems: "center"
                                                })
                                            }}
                                        />  
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Nama Vendor:</label>
                                        <input name='name' type="text" className="form-control" placeholder='Nama vendor...' onChange={(e) => onChangeEventName(e.target.value, 'name')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="code">Kode Vendor:</label>
                                        <input name='code' type="text" className="form-control" placeholder='Kode vendor...' onChange={(e) => onChangeEventName(e.target.value, 'code')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nib">NIB:</label>
                                        <input name='nib' type="text" className="form-control" placeholder='NIB...' onChange={(e) => onChangeEventName(e.target.value, 'nib')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="npwp">NPWP:</label>
                                        <input name='npwp' type="text" className="form-control" placeholder='NPWP...' onChange={(e) => onChangeEventName(e.target.value, 'npwp')} />
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <div className="form-group">
                                        <label htmlFor="email">Email:</label>
                                        <input name='email' type="text" className="form-control" placeholder='Email...' onChange={(e) => onChangeEventName(e.target.value, 'email')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">No. Telepon:</label>
                                        <input name='phone' type="text" className="form-control" placeholder='Telepon...' onChange={(e) => onChangeEventName(e.target.value, 'phone')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="pic">PIC:</label>
                                        <input name='pic' type="text" className="form-control" placeholder='Person in charge...' onChange={(e) => onChangeEventName(e.target.value, 'pic')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address">Alamat:</label>
                                        <ReactQuill name="address" theme="snow" onChange={(e)=> onChangeEventName(e, 'address')} />
                                    </div>
                                </Col>
                            </Row>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary me-2">Submit</button>
                                <Link to={`/vendor/`} className="btn btn-light">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </Col>
        </Row>
    )

}

export default FormAddVendorComponent;