import React, { useEffect, useState } from 'react';
import axios from "axios";
import useSWR from 'swr';
import config from '../../config';
import Select from "react-select";
import { Button, Card, Col, Row, Tabs, Tab, CardBody, CardTitle } from 'react-bootstrap';
import TableJournalComponent from './tableJournal';
import FormAddJournalComponent from './formAddJournal';
import CurrencyInput from 'react-currency-input-field';
import { listOfMonthOptions, listOfYearOptions } from '../../features/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { getIndexById } from '../../features/utils';
import LoadingSpinner from "../global/loadingSpinner";

const FormCreateJournalComponent = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showTab, setShowTab] = useState(false);
    const [addModalShow, setAddModalShow] = useState(false);
    const [typeList, setTypeList] = useState("");
    const [currencyList, setCurrencyList] = useState("");
    const [vendorListGroup, setVendorListGroup] = useState("");
    const [accountDropdownList, setAccountDropdownList] = useState("");
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState([]);
    const [selectedType, setSelectedType] = useState([]);
    const [selectedRate, setSelectedRate] = useState("");
    const [rawMainData, setRawMainData] = useState([]);
    const [mainData, setMainData] = useState([]);
    const saveNewAccount = (e) => {
        e.preventDefault();
        //console.log("Main Data Post:", mainData);
    }

    const showAddJournalModal = () => {
        setAddModalShow(true);
    }

    const mappingDropdownData = (data) => {
        let resData = [];
        function mappingChilds(dataChild, level) {
            let resDataChilds = [];
            for(let c=0; c < dataChild.length;c++) {
                let child = dataChild[c];
                if(child.childs.length) {
                    let getChilds = mappingChilds(child.childs, level + 1);
                    resDataChilds =[
                        ...resDataChilds,
                        ...getChilds
                    ];
                } else {
                    resDataChilds =[
                        ...resDataChilds,
                        {
                            value: child.id,
                            label: child.name,
                            id: child.id,
                            uuid: child.uuid,
                            code: child.code,
                            level: level + 1
                        }
                    ];
                }
            }
            return resDataChilds;
        };
        for(let a = 0; a < data.length; a++) {
            const level = 0;
            let rowData = data[a];
            if(rowData?.childs.length) {
                resData = [...resData,
                    ...mappingChilds(rowData.childs, level)
                ];
            } else {
                resData = [...resData,
                    {
                        id: rowData.id,
                        uuid: rowData.uuid,
                        label: rowData.name,
                        code: rowData.code,
                        level: level
                    }
                ];
            }
        }
        return resData;
    };
    
    useEffect(()=> {
        // Get Vendor Group
        const getVendorGroup = async() => {
            const response = await axios.get( config.server.backend + `/vendor-company-group/${props.user.company.uuid}` );
            const resData = response.data;
            let dataVendorListGroup = [];
            resData.map( VG => {
                let dataVG = [];
                const {id, code, name, vendortypes} = VG;
                if(vendortypes.length) {
                    vendortypes.map( VT =>(
                        dataVG = [
                            ...dataVG,
                            {
                                value: id + "-" + VT.id,
                                label: name + " ( "+VT.name+" )",
                                id: id,
                                code: code,
                                vendortypes: VT
                            }
                        ]
                    ));
                } else {
                    dataVG = [
                        ...dataVG,
                        {
                            value: id,
                            label: name,
                            code: code
                        }
                    ]
                }
                return dataVendorListGroup = [...dataVendorListGroup, ...dataVG];
            });
            setVendorListGroup(dataVendorListGroup);
        };

        // // Get account Dropdown
        const getAccountDropdown = async() => {
            const response = await axios.get( config.server.backend + `/account-dropdown/${props.user.company.uuid}` );
            const mappingResult = mappingDropdownData(response.data);
            setAccountDropdownList(mappingResult);
        };
        if(props?.user) {
            getVendorGroup();
            getAccountDropdown();
        }
    },[props]);

    // // Get Type List
    const getTypeList = async(url) => {
        await axios.get( url )
        .then(response => {
            const resData = response.data;
            let dataTypeList = [];
            let preMainData = [];
            for(let a=0; a < resData.length; a++) {
                const type = resData[a];
                // for dropdown
                dataTypeList = [
                    ...dataTypeList,
                    {
                        value: type.id,
                        label: type.name,
                        code: type.code,
                        uuid: type.uuid
                    }
                ]
                setTypeList(dataTypeList);
                // for main data
                preMainData = [
                    ...preMainData,
                    {
                        id: type.id,
                        label: type.name,
                        code: type.code,
                        data: [],
                        root_id: "",
                        root_uuid: ""
                    }
                ]
            };
            setRawMainData(preMainData);
            //setMainData(preMainData);
        })
        .catch(error => {
            console.error(error.message)
        });
    };
    useSWR( config.server.backend + '/types', getTypeList);

    // // Get Currency List
    const getCurrencyList = async(url) => {
        const response = await axios.get( url );
        const resData = response.data;
        let dataCurrencyList = [];
        resData.map(currency => (
            dataCurrencyList = [...dataCurrencyList,
                {
                    value: currency.id,
                    label: currency.name,
                    code: currency.code,
                    uuid: currency.uuid
                }
            ]
        ));
        setCurrencyList(dataCurrencyList);
    };
    useSWR( config.server.backend + '/currency/', getCurrencyList);

    // Get Data Journal
    const clearMainData = (data)=> {
        for(let a=0; a < data.length; a++) {
            data[a]["data"] = [];
        }
        return data;
    }
    const getJournalByMonthAndYear = async(month, year) => {
        if(month && year) {
            setIsLoading(true);
            await axios.get( config.server.backend + `/journal/${month}/${year}` )
            .then( (response) => { 
                const responseData = response.data;
                const extendMainData = clearMainData(rawMainData);
                const dataJournal = JSON.parse(responseData.data);
                const dataRootID = responseData.id;
                const dataRootUUID = responseData.uuid;
                for(let a=0; a < dataJournal.length; a++) {
                    let extData = dataJournal[a];
                    extData["index"] = a;
                    const dataIndex = getIndexById(extendMainData, extData.type.value);
                    if(dataIndex !== -1) {     
                        extendMainData[dataIndex]["root_id"] = dataRootID;
                        extendMainData[dataIndex]["root_uuid"] = dataRootUUID;
                        extendMainData[dataIndex]["data"].push(extData);
                    }
                }
                setMainData(extendMainData);
                setShowTab(true);
            })
            .catch(error=> {
                const extendMainData = clearMainData(rawMainData);
                setMainData(extendMainData);
                setShowTab(false);
                console.error(error.message)
            })
            .finally(()=>{
                setIsLoading(false)
            });
        }
    };
    const onChangeSelectMonth = (value) => {
        setSelectedMonth(value);
        getJournalByMonthAndYear(value.value, selectedYear.value);
    }
    const onChangeSelectYear = (value) => {
        setSelectedYear(value);
        getJournalByMonthAndYear(selectedMonth.value, value.value);
    }

    return (
        <>
        {
            isLoading && ( <LoadingSpinner /> )
        }
        <FormAddJournalComponent
            show={addModalShow}
            user={props?.user}
            backdrop="static"
            keyboard={false}
            month={selectedMonth}
            year={selectedYear}
            vendor={selectedVendor}
            vendorlist={vendorListGroup}
            currency={selectedCurrency}
            currencylist={currencyList}
            rate={selectedRate}
            type={selectedType}
            typelist={typeList}
            accountdropdownlist={accountDropdownList}
            onHide={(e) => {
                    setAddModalShow(false);
                    setShowTab(false);
                    getJournalByMonthAndYear(selectedMonth.value, selectedYear.value);
                }
            }
        />
        <form id='formCreateAccount' onSubmit={saveNewAccount}>
            <Card>
                <Card.Body>
                    <Card.Title className='card-title-dash'>
                        Membuat Laporan Journal
                    </Card.Title>
                    <p className='card-description'>Fitur untuk proses pembuatan Journal </p>
                    <Row>
                        <Col md={4}>
                            <div className="border rounded-2 p-3">
                                <div className="form-group">
                                    <label>Bulan:</label>
                                    <Select
                                    options = { listOfMonthOptions() }
                                    placeholder='Pilih bulan...'
                                    name='month'
                                    className='select-custom fw-bold'
                                    styles={{
                                        singleValue: (base) => ({
                                            ...base,
                                            display: "flex",
                                            alignItems: "center"
                                        })
                                    }}
                                    onChange={(value)=>onChangeSelectMonth(value)}
                                
                                />  
                                </div>
                                <div className="form-group">
                                    <label>Tahun:</label>
                                    <Select
                                        options = { listOfYearOptions() }
                                        placeholder='Pilih tahun...'
                                        name='year'
                                        className='select-custom fw-bold'
                                        styles={{
                                            singleValue: (base) => ({
                                                ...base,
                                                display: "flex",
                                                alignItems: "center"
                                            })
                                        }}
                                        onChange={(value)=>onChangeSelectYear(value)}
                                    />  
                                </div>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="border rounded-2 p-3">
                                <Card.Title className='card-title-dash pt-3'>
                                    Penambahan Data
                                </Card.Title>
                                <p className='card-description'>Pengaturan dasar untuk memasukan pilihan yang mungkin berulang saat menambah data Journal.<br />Ini akan membantu untuk tidak selalu memilih pilihan sama berulang-ulang di form penambahan data.</p>
                                <div className="form-group">
                                    <label>Vendor:</label>
                                    <div className="d-flex">
                                        <Select
                                            options = { vendorListGroup }
                                            placeholder='Pilih vendor...'
                                            name='vendor'
                                            className='select-custom fw-bold w-100 me-auto'
                                            styles={{
                                                singleValue: (base) => ({
                                                    ...base,
                                                    display: "flex",
                                                    alignItems: "center"
                                                })
                                            }}
                                            onChange={(value)=>setSelectedVendor(value)}
                                            isDisabled={!selectedMonth.value || !selectedYear.value}
                                        />  
                                        {
                                            vendorListGroup.length === 0 && (
                                                <a href='/vendor/' title='Tambah data vendor' className='ms-2 btn btn-link btn-sm'><FontAwesomeIcon icon={faPencilAlt} /></a>
                                            )
                                        }
                                        
                                    </div>
                                    
                                </div>
                                <Row>
                                    <Col md={5}>
                                        <div className="form-group">
                                            <label>Type Account:</label>
                                            <Select
                                                options = { typeList }
                                                placeholder='Pilih type account...'
                                                name='type'
                                                className='select-custom fw-bold'
                                                styles={{
                                                    singleValue: (base) => ({
                                                        ...base,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    })
                                                }}
                                                onChange={(value)=>setSelectedType(value)}
                                                isDisabled={!selectedMonth.value || !selectedYear.value}
                                            />  
                                        </div>
                                    </Col>
                                    
                                    <Col md={7}>
                                       
                                    <Row>
                                            <Col md={7}>
                                                <div className="form-group">
                                                    <label>Currency:</label>
                                                    <Select
                                                        options = { currencyList }
                                                        placeholder='Pilih currency...'
                                                        name='currency'
                                                        className='select-custom fw-bold'
                                                        styles={{
                                                            singleValue: (base) => ({
                                                                ...base,
                                                                display: "flex",
                                                                alignItems: "center"
                                                            })
                                                        }}
                                                        onChange={(value)=>setSelectedCurrency(value)}
                                                        isDisabled={!selectedMonth.value || !selectedYear.value}
                                                    />  
                                                </div>
                                            </Col>
                                            <Col md={5}>
                                                <div className="form-group">
                                                    <label>Rate:</label>
                                                    <CurrencyInput
                                                        name="rate"
                                                        placeholder="Please input price"
                                                        className='form-control fw-bold'
                                                        prefix=''
                                                        decimalSeparator='.'
                                                        defaultValue={1}
                                                        decimalsLimit={0}
                                                        onValueChange={(value)=>setSelectedRate(value)}
                                                        disabled={!selectedMonth.value || !selectedYear.value}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>     
                                    </Col>
                                </Row>
                                <div className='d-flex'>
                                    <Button disabled={!selectedMonth.value || !selectedYear.value} className='ms-auto btn btn-primary btn-md text-white mb-0 me-0' variant="primary" onClick={() => showAddJournalModal(0)}>
                                        <i className="mdi mdi-plus"></i> Tambah Data Journal
                                    </Button>
                                </div>
                                </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {
                showTab && (
                    <Card className='mt-3'>
                        <CardBody>
                            <CardTitle>Journal {selectedMonth.label} { selectedYear.label }</CardTitle>
                            <Tabs
                                defaultActiveKey={mainData[0].label}
                                id="tabsCreateJournal"
                                className="mb-3 tabs-no-border"
                                >
                                    {
                                        mainData.map((md) => (
                                            <Tab key={md.id} eventKey={md.label} title={md.label}>
                                                <TableJournalComponent 
                                                    label={md.label}
                                                    data={md}
                                                    user={props.user}
                                                    vendorlist={vendorListGroup}
                                                    currencylist={currencyList}
                                                    typelist={typeList}
                                                    accountdropdownlist={accountDropdownList}
                                                />
                                            </Tab>
                                        ))
                                        
                                    }

                            </Tabs>
                        </CardBody>
                    </Card>
                )
            }
        </form>
        </>
    )
}

export default FormCreateJournalComponent;