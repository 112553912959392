import React from 'react';
import Layout from '../../views/layout';
import LayoutBlank from '../../views/layoutBlank';
import FormEditCategoryComponent from '../../components/categories/formEditCategory';
import { useSelector} from 'react-redux';

const EditCategoryPage = () => {
  const { user } = useSelector(( state => state.auth));
  return (
    <>
      {user && user.role === "admin" ? (
          <Layout>
              <FormEditCategoryComponent/>
          </Layout>
      ) : (
        <LayoutBlank />
      )}
    </>
  )
};

export default EditCategoryPage;