import React from 'react';
import Layout from '../../views/layout';
import LayoutBlank from '../../views/layoutBlank';
import CurrencyListComponent from '../../components/currencies/currencyList';
import { useSelector} from 'react-redux';

const CurrencyListPage = () => {
  const { user } = useSelector(( state => state.auth));
  return (
    <Layout>
      {
        user && user.role === "admin" ? <CurrencyListComponent /> : <LayoutBlank/>
      }
    </Layout>
  )
}

export default CurrencyListPage;