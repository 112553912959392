import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate  } from 'react-router-dom';
import useSWR from 'swr';
import config from '../../config';
import LoadingSpinner from "../global/loadingSpinner";

const FormEditTypeComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [code, setCode] = useState("");   
    const [symbol, setSymbol] = useState("");  
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(()=> {
        const getTypeId = async() => {
            try {
                const response = await axios.get(config.server.backend + `/types/${id}`);
                setName(response.data.name);
                setCode(response.data.code);
                setSymbol(response.data.symbol);
            } catch (error) {
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
            }
        }
        getTypeId();
   }, [id]);    

    const updateType = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        try {                    
            await axios.patch( config.server.backend + `/types/${id}`, {
                name: name,
                code: code,
                symbol: symbol
            }).then(() => {
                navigate('/types');
            });
        } catch (error) {
            setIsLoading(false);
            if(error.response) {
                setMsg(error.response.data.msg);
            }
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
        }
    }; 
    
    const getType = async() => {
        const response = await axios.get( config.server.backend + '/types' );
        return response.data;
    };  

    // realtime data pakai SWR
    const {data} = useSWR('typesData', getType);
    if(!data) return <span>Data is empty...</span>;

    return (
        <div className='row'>
            
            {
                isLoading && ( <LoadingSpinner /> )
            }
            <div className="col-lg-6 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Add new type</h4>
                        <p className="card-description">Register the new type here below.</p>

                        {msg &&
                            <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{msg}</div>
                        }
                        <form onSubmit={updateType}>
                            <div className="form-group">
                                <label htmlFor="name">Nama Tipe</label>
                                <input name='name' type="text" className="form-control" placeholder='Name tipe...' value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="code">Kode Tipe</label>
                                <input name='code' type="text" className="form-control" placeholder='Kode tipe...' value={code} onChange={(e) => setCode((e.target.value).toUpperCase())} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="symbol">Simbol</label>
                                <input name='symbol' type="text" className="form-control" placeholder='Simbol...' value={symbol} onChange={(e) => setSymbol((e.target.value).toUpperCase())} />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary me-2">Submit</button>
                                <Link to={`/types/`} className="btn btn-light">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default FormEditTypeComponent;