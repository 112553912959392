import React, { useEffect, useState } from 'react';
import {Link } from 'react-router-dom';
import axios from "axios";
import useSWR, { mutate} from 'swr';
import config from '../../config';
import { confirmAlert } from 'react-confirm-alert'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faRefresh, faRemove } from '@fortawesome/free-solid-svg-icons';
import MUIDataTable from "mui-datatables";
import { TableCell } from '@mui/material';
import { Badge } from 'react-bootstrap';


const VendorListComponent = (props) => {
    const [dataTable, setDataTable] = useState({});
    const deleteVendor = (vendorId, titleName) => {
        confirmAlert({
            overlayClassName: 'global-overlay-bg',
            customUI: ({ onClose }) => {
              return (
                <div className='card'>
                    <div className="card-body">
                    <h4 className='card-title'>Are you sure?</h4>
                    <p className='card-description'>You will deleting <code><strong>{titleName}</strong></code>?</p>
                    <button className='btn btn-sm btn-inverse-primary' onClick={onClose}><FontAwesomeIcon icon={faRefresh} className='me-2' /> No, please back.</button>
                    <button 
                        className='btn btn-sm btn-inverse-danger ms-3'
                        onClick={async() => {
                            // hapus database
                            await axios.delete( `${config.server.backend}/vendor/${vendorId}` );
                            mutate('dataVendor');
                            onClose();
                        }}
                    ><FontAwesomeIcon icon={faRemove} className='me-2' />
                        Yes, Delete it!
                    </button>
                    </div>
                </div>
              );
            }
        });   
    };

     const getVendor = async() => {
        if(props.user?.company) {
            let vendorEndpoint = props.user.role === 'admin' ? '/vendor/' : '/vendor-company/' + props.user.company.uuid ;
            const response = await axios.get(config.server.backend + vendorEndpoint);
            return(response.data);
        } else {
            return false;
        }
    };
    // realtime data pakai SWR
    const {data} = useSWR('dataVendor', getVendor);
    mutate('dataVendor');

    useEffect(()=>{
        setDataTable(data);
    }, [data]);

    const columns = [
        {
            name: 'name',
            label: 'Nama Vendor',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'vendortypes',
            label: 'Tipe Vendor',
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let vendorTypeName = [];
                    value.map((val, idx) => vendorTypeName.push(
                        <Badge key={idx} bg='success' className='py-1 px-2 text-white badge-opacity-success'>{val.name}</Badge>
                    ));
                    return <div className='hstack gap-1 flex-wrap'>{ vendorTypeName }</div>;
                }
            },
        },
        {
            name: 'nib',
            label: 'NIB',
            options: {
                filter: true,
            },
        },
        {
            name: 'npwp',
            label: 'npwp',
            options: {
                filter: true,
            },
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
            },
        },
        {
            name: 'phone',
            label: 'Telepon',
            options: {
                filter: true,
            },
        },
        {
            name: 'pic',
            label: 'PIC',
            options: {
                filter: true,
            },
        },
        {
            name: 'uuid',
            options: {
                filter: false,
                sort: false,
                customHeadRender : ( columnMeta, handleToggleColumn, sortOrder) => {
                    return (
                        <TableCell key={columnMeta.index}>&nbsp;</TableCell>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div className="btn-group">
                        <button type="button" className="btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </button>
                        <ul className="dropdown-menu">
                            <li> <Link to={`/vendor/edit/${value}/`} className='dropdown-item'>Edit</Link></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><button onClick={() => deleteVendor(value, tableMeta.tableData[tableMeta.rowIndex].name)} className='btn btn-link dropdown-item'>Delete</button></li>
                        </ul>
                    </div>
                )
            },
        },
    ];
  
    const options = {
        download: false,
        print: false,
        filter: false,
        viewColumns: false,
        responsive: 'standard',
        selectableRows: 'none',
        setTableProps: () => ({className: "table-vendors"})
    };

    return (
        <div className="grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start">
                        <div>
                            <h4 className="card-title card-title-dash">Vendor List</h4>
                            <p className="card-subtitle card-subtitle-dash">List of all data vendor</p>
                        </div>
                        <div>
                            <Link to="/vendor/add/" className='btn btn-primary btn-md text-white mb-0 me-0'><i className="mdi mdi-plus"></i> Add new vendor</Link>
                        </div>
                    </div>
                    <div className="table-responsive">
                        {data && dataTable?.length ? (
                            <MUIDataTable
                                data={dataTable}
                                columns={columns}
                                options={options}
                            />
                        ) : (
                            <i className='text-xxs'>...</i>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorListComponent;