import React, {useState, useEffect} from 'react';
import {Link } from 'react-router-dom';
import axios from "axios";
import config from '../../config';
import AvatarImage from '../../components/global/avatarImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';


const UserListComponent = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async() => {
        const response = await axios.get( config.server.backend + '/users' );
        setUsers(response.data);
    };

    const deleteUser = async(productId) => {
        await axios.delete( `${config.server.backend}/users/${productId}` );
        getUsers();
    };

  return (
    <div className="grid-margin stretch-card">
        <div className="card">
            <div className="card-body">
                <div className="d-sm-flex justify-content-between align-items-start">
                    <div>
                        <h4 className="card-title card-title-dash">User Listing</h4>
                        <p className="card-subtitle card-subtitle-dash">All data users</p>
                    </div>
                    <div>
                        <Link to="/users/add/" className='btn btn-primary btn-md text-white mb-0 me-0'><i className="mdi mdi-account-plus"></i> Add new user</Link>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>No</th>
                            <th>User</th>
                            <th>First name</th>
                            <th>Progress</th>
                            <th>Status</th>
                            <th>Level</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map((user, index) => (
                            <tr key={user.uuid}>
                                <td>{index+1}</td>
                                <td className="py-1">{AvatarImage(user.email, "24")}</td>
                                <td className='text-capitalize'>{user.name}</td>
                                <td>
                                    <div className="progress">
                                        <div className="progress-bar bg-success" role="progressbar" style={{width: "25%"}}
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </td>
                                <td>{user.status === 0 ? "inactive" : "active"}</td>
                                <td>{user.level === 1 ? "supervisor" : "accountant"}</td>
                                <td>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li> <Link to={`/users/edit/${user.uuid}/`} className='dropdown-item'>Edit</Link></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><button onClick={() => deleteUser(user.uuid, user.name)} className='btn btn-link dropdown-item'>Delete</button></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UserListComponent;