import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import "./app.css";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import 'react-tabs/style/react-tabs.css';
import 'react-quill/dist/quill.snow.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown/style.css';
import axios from 'axios';

axios.defaults.withCredentials = true;

const container = document.getElementById('mainRoot');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}> 
      <App />
    </Provider>
  </React.StrictMode>
);


