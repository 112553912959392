import React from 'react';
import Layout from '../../views/layout';
import LayoutBlank from '../../views/layoutBlank';
import FormEditVendorTypeComponent from '../../components/vendorsType/formEditVendorType';
import { useSelector} from 'react-redux';

const EditVendorTypePage = () => {
  const { user } = useSelector(( state => state.auth));

  return (
    <Layout>
      {
        user && user.role === "admin" ? <FormEditVendorTypeComponent /> : <LayoutBlank/>
      }
    </Layout>
  )
};

export default EditVendorTypePage;