import React from 'react';
import Layout from '../../views/layout';
import FormEditUserComponent from '../../components/users/formEditUsers';

const EditUserPage = () => {

  return (
    <Layout>
        <FormEditUserComponent/>
    </Layout>
  )
};

export default EditUserPage;