import React from 'react';
import Layout from '../../views/layout';
import LayoutBlank from '../../views/layoutBlank';
import CompanyListComponent from '../../components/companies/companyList';
import { useSelector} from 'react-redux';

const CompanyListPage = () => {
  const { user } = useSelector(( state => state.auth));
  return (
    <Layout>
      {
        user && user.role === "admin" ? <CompanyListComponent /> : <LayoutBlank/>
      }
    </Layout>
  )
}

export default CompanyListPage;