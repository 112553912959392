import Layout from '../views/layout';
import Welcome from '../components/global/welcome';

const Dashboard = () => {

  return (
    <Layout><Welcome></Welcome></Layout>
  )
}

export default Dashboard