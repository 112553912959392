import React from 'react';
import Layout from '../../views/layout';
import FormAddSettingComponent from '../../components/settings/formAddSetting';


const AddSettingPage = () => {
  return (
    <Layout>
        <FormAddSettingComponent />
    </Layout>
  )
}

export default AddSettingPage;