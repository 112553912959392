import React from 'react';
import Layout from '../../views/layout';
import LayoutBlank from '../../views/layoutBlank';
import FormEditTypeComponent from '../../components/types/formEditType';
import { useSelector} from 'react-redux';

const EditTypePage = () => {
  const { user } = useSelector(( state => state.auth));
  return (
    <Layout>
      {
        user && user.role === "admin" ? <FormEditTypeComponent /> : <LayoutBlank/>
      }
    </Layout>
  )
};


export default EditTypePage;