import React from 'react';
import Layout from '../../views/layout';
import SettingListComponent from '../../components/settings/settingList';


const SettingListPage = () => {
  return (
    <Layout>
        <SettingListComponent />
    </Layout>
  )
}

export default SettingListPage;