import React, {useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginUser, reset  } from '../../features/authSlice';
import config from '../../config';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isError, isSuccess, isLoading, message} = useSelector(
        (state) => state.auth
    );

    const Auth = (e) => {
        e.preventDefault();
        dispatch(LoginUser({email, password}));
    };

    useEffect(() => {
            if( user && isSuccess) {
                navigate("/dashboard/");
            }
            dispatch(reset());
        }, [user, isSuccess, dispatch, navigate]
    );
  

  return (
    <div className="container-scroller">
    <div className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="content-wrapper d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-xxl-4 col-xl-6 col-lg-8 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5 rounded-3 shadow">
              <div className="brand-logo">
                <img src={ config.server.backend + "/1706267809522-926927595.png" } alt="Journal App Itechnosia" />
              </div>
              <h4>Hello! let's get started</h4>
              <h6 className="fw-light pb-3">Sign in to continue.</h6>
              
                        
              {isError &&
                    <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{message}</div>
                }

              <form onSubmit={Auth} method='POST' className="pt-3">
                <div className="form-group">
                    <input 
                        id="email"
                        type="email" 
                        className="form-control form-control-lg" 
                        value={email} 
                        onChange={(e)=>setEmail(e.target.value)} 
                        placeholder="Email"
                        autoComplete="on"
                        disabled={isLoading}
                    />
                </div>
                <div className="form-group">
                    <input type="password" 
                        id="password"
                        className="form-control form-control-lg" 
                        value={password} 
                        onChange={(e)=>setPassword(e.target.value)} 
                        placeholder="********"
                        autoComplete="off"
                        disabled={isLoading}
                    />
                </div>
                <div className="mt-3">
                    <button type="submit" disabled={isLoading} className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                        {isLoading ? "Loading..." : "SIGN IN"}
                    </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input" />
                      Keep me signed in
                    </label>
                  </div>
                  <a href="/" className="auth-link text-black">Forgot password?</a>
                </div>
                {/* <div className="mb-2">
                  <button type="button" className="btn btn-block btn-facebook auth-form-btn">
                    <i className="ti-facebook me-2"></i>Connect using facebook
                  </button>
                </div> */}
                <div className="text-center mt-4 fw-light">
                  Don't have an account? <a href="register.html" className="text-primary">Create</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
};

export default Login;