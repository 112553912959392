import React, {useState} from 'react';
import axios from 'axios';
import { Link, useNavigate  } from 'react-router-dom';
import useSWR from 'swr';
import config from '../../config';
import * as Utils from '../../features/utils';
import LoadingSpinner from "../global/loadingSpinner";
import ReactQuill from 'react-quill';
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';

const FormAddCompanyComponent = () => {
    moment.tz.setDefault("Asia/Jakarta");
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [showLogo, setShowLogo] = useState(null);
    const navigate = useNavigate();
    const [detailCompany, setDetailCompany] = useState({
        name: "",
        nib: "",
        npwp: "",
        email: "",
        status: "",
        membership: "",
        phone: "",
        registered: "",
        userId: "",
        address: "",
        logo: ""
    });

    const onChangeLogo = (file) => {
        const dataDetailCompany = detailCompany;
        dataDetailCompany['logo'] = file;
        setShowLogo(file);
        setDetailCompany(dataDetailCompany);
    }
    
    const onChangeEvent = (e) => {              
        const { name, value } = e.target;
        const dataDetailCompany = detailCompany;
        dataDetailCompany[name] = value;
        setDetailCompany(dataDetailCompany);
    }
    
    const onChangeEventName = (value, name) => {  
        const dataDetailCompany = detailCompany;
        dataDetailCompany[name] = value;
        setDetailCompany(dataDetailCompany);
    }

    const onChangeEventSelect = (e) => {
        const uid = e.value;
        const detailCompanyExtend = detailCompany;
        const ObjectByID = Utils.getObjectByProp(data, uid);
        if(ObjectByID !== undefined) {
            const email = ObjectByID.email;
            const phone = ObjectByID.phone;
            document.querySelector('[name="email"]').value = email ? email : '';
            document.querySelector('[name="phone"]').value = phone ? phone : '';
            detailCompanyExtend.email = email;
            detailCompanyExtend.phone = phone;
        }
        // set new data to detail object
        detailCompanyExtend.userId = uid;
        setDetailCompany(detailCompanyExtend);
    }

    const saveCompany = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        const postData = detailCompany;
        postData.registered = new Date();
        try {
            if(postData.logo) {
                await axios.post( 
                    config.server.backend + '/files/uploadimage', 
                    {
                        image: postData.logo,
                        name: postData.name
                    },
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                ).then(async (response) => {
                    if(response.status === 200) { 
                        const imageFileName = response.data.image;
                        postData.logo = imageFileName;                 
                        await axios.post( config.server.backend + '/company/', postData ).then(() => {
                            navigate('/company/');
                        }).catch((error) => {
                            setIsLoading(false);
                            if(error.response) {
                                setMsg(error.response.data.msg);
                            }
                            Utils.scrollToTop();
                            //remove image on submitting data failed
                            axios.delete( config.server.backend + '/files/deleteimage/' + imageFileName );
                        });
                    } else {
                        setMsg("Upload image gagal");
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    if(error.response) {
                        setMsg(error.response.data.msg);
                    }
                    Utils.scrollToTop();
                });
            } else {
                await axios.post( config.server.backend + '/company/', postData ).then(() => {
                    navigate('/company/');
                }).catch((error) => {
                    setIsLoading(false);
                    if(error.response) {
                        setMsg(error.response.data.msg);
                    }
                    Utils.scrollToTop();
                });
            }   
        
        } catch (error) {
            setIsLoading(false);
            if(error.response) {
                setMsg(error.response.data.msg);
            }
            Utils.scrollToTop();
        }
    };

    const getDataCustomers = async() => {
        const response = await axios.get( config.server.backend + '/customers' );
        const responseData = response.data;
        return responseData;
    };  

    // realtime data pakai SWR
    const {data} = useSWR('customerData', getDataCustomers);

    return (
        <div className='row'>
            
            {
                isLoading && ( <LoadingSpinner /> )
            }
            <div className="col-xl-12 col-xxl-8 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Tambah Perusahaan Baru</h4>
                        <p className="card-description">Register the new company here below.</p>

                        {msg &&
                            <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{msg}</div>
                        }
                        <form onSubmit={saveCompany} encType='multipart/form-data'>
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Nama Perusahaan:</label>
                                                <input name='name' type="text" className="form-control" placeholder='Nama perusahaan...' onChange={(e) => onChangeEvent(e)} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="nib">NIB:</label>
                                                <input name='nib' type="text" className="form-control" placeholder='NIB...' onChange={(e) => onChangeEvent(e)} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="npwp">NPWP:</label>
                                                <input name='npwp' type="text" className="form-control" placeholder='NPWP...' onChange={(e) => onChangeEvent(e)} />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="npwp">Logo:</label>
                                                <label className="file-label">
                                                    {
                                                        showLogo && (
                                                            <img alt='' src={URL.createObjectURL(showLogo)} /> 
                                                        )
                                                    }
                                                    <input className="file-input" accept="image/*" type="file" name="logo" placeholder='logo' onChange={(e) => onChangeLogo(e.target.files[0])} />
                                                    <span className="file-cta">
                                                    <span className="file-icon">
                                                        <FontAwesomeIcon icon={faUpload} />
                                                    </span>
                                                    <span className="file-label">
                                                    { showLogo && ('Ganti')} Logo <small><i>(format: *.svg, *.jpg, *.jpeg, *.png)</i></small>
                                                    </span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userId">Supervisor:</label>
                                        <Select
                                            options = {
                                                data?.map((customer) => (
                                                    {
                                                        value: customer.id,
                                                        label: customer.name
                                                    }
                                                ))
                                            }
                                            placeholder='Pilih customer...'
                                            name='userId'
                                            onChange={(e) => {onChangeEventSelect(e)}}
                                            className='select-custom'
                                            styles={{
                                                singleValue: (base) => ({
                                                    ...base,
                                                    display: "flex",
                                                    alignItems: "center"
                                                })
                                            }}
                                        />  
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email Perusahaan:</label>
                                        <input name='email' type="text" className="form-control" placeholder='Email...' onChange={(e) => onChangeEvent(e)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">No. Telepon:</label>
                                        <input name='phone' type="text" className="form-control" placeholder='Telepon...' onChange={(e) => onChangeEvent(e)} />
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="form-group">
                                        <label htmlFor="status">Status:</label>
                                        <input name='status' type="text" className="form-control" placeholder='Status...'  onChange={(e) => onChangeEvent(e)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="membership">Tipe Keanggotaan:</label>
                                        <input name='membership' type="text" className="form-control" placeholder='Keanggotaan...' onChange={(e) => onChangeEvent(e)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address">Alamat:</label>
                                        <ReactQuill name="address" theme="snow" onChange={(e)=> onChangeEventName(e, 'address')} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary me-2">Submit</button>
                                <Link to={`/company/`} className="btn btn-light">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>
    )

}

export default FormAddCompanyComponent;