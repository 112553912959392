import React, {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../config';
import * as Utils from '../../features/utils';
import LoadingSpinner from "../global/loadingSpinner";
import Select from "react-select";
import { Button, Col, Modal, Row} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import CurrencyInput from 'react-currency-input-field';

const FormAddJournalComponent = (props) => { 
    const [msg, setMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [defaultCurrency, setDefaultCurrency] = useState("");
    const [dataPost, setDataPost] = useState({
        account: {},
        vendor: {},
        type: {},
        currency:{},
        rate: "",
        amount: "",
        description: ""
        
    });

    const saveNewRowJournal = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        let objectPost = {
            companyId: props.user.company.id,
            createdBy: props.user.uuid,
            approvedBy: "",
            status: 0,
            month: props.month.value,
            year: props.year.value,
            data: dataPost
        }
        
        try {
            await axios.post( config.server.backend + '/journal', objectPost).then(() => {
                setIsLoading(false);
                setMsg("");
                setDataPost({
                    account: {},
                    vendor: {},
                    type: {},
                    currency:{},
                    rate: "",
                    amount: "",
                    description: ""
                    
                });
                props.onHide();
            }).catch((error) => {
                setIsLoading(false);
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
                Utils.scrollToTop();
            });
        
        } catch (error) {
            setIsLoading(false);
            if(error.response) {
                setMsg(error.response.data.msg);
            }
            Utils.scrollToTop();
        }
    };
    
    const eventOnChange = (value, name) => {
        const dataPostExtend = dataPost;
        dataPostExtend[name] = value;
        setDataPost(dataPostExtend);
        // set currency on change
        if(name === 'currency') {
            setDefaultCurrency(value.code);
        }
    }

    useEffect(()=>{
        if(props) {
            if(props?.currency) {
                setDefaultCurrency(props.currency.code);
            }
            const dataPostExtend = dataPost;
            dataPostExtend.vendor = props?.vendor;
            dataPostExtend.type = props?.type;
            dataPostExtend.currency = props?.currency;
            dataPostExtend.rate = props?.rate;
            setDataPost(dataPostExtend);
        }
    }, [props, dataPost])

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <form id='formAddNewRowJournal' onSubmit={saveNewRowJournal}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        Tambah Data Journal
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            isLoading && ( <LoadingSpinner /> )
                        }
                        { msg && <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{msg}</div> }
                        
                        <Row>
                            <Col md={6}>
                            <div className="form-group">
                                    <label>Account:</label>
                                    {
                                        props.typelist && props.type && (
                                            <Select
                                                options = {props.accountdropdownlist}
                                                placeholder='Pilih account...'
                                                id='account'
                                                name='account'
                                                onChange={(e) => {eventOnChange(e, 'account')}}
                                                className='select-custom  fw-bold'
                                                styles={{
                                                    singleValue: (base) => ({
                                                        ...base,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    })
                                                }}
                                            />  
                                        )
                                    }    
                                </div>
                                <div className="form-group">
                                    <label>Type Account:</label>
                                    {
                                        props.typelist && props.type && (
                                            <Select
                                                options = {props.typelist}
                                                defaultValue={props.type}
                                                placeholder='Pilih type account...'
                                                id='type'
                                                name='type'
                                                onChange={(e) => {eventOnChange(e, 'type')}}
                                                className='select-custom  fw-bold'
                                                styles={{
                                                    singleValue: (base) => ({
                                                        ...base,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    })
                                                }}
                                            />  
                                        )
                                    }    
                                </div>
                                <div className="form-group">
                                    <label htmlFor="vendor">Nama Vendor:</label>
                                    {
                                        props.vendorlist && props.vendor && (
                                            <Select
                                                options = {props.vendorlist}
                                                defaultValue={props.vendor}
                                                placeholder='Pilih vendor...'
                                                id='vendor'
                                                name='vendor'
                                                onChange={(e) => {eventOnChange(e, 'vendor')}}
                                                className='select-custom  fw-bold'
                                                styles={{
                                                    singleValue: (base) => ({
                                                        ...base,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    })
                                                }}
                                            />  
                                        )
                                    }    
                                </div>
                                <Row>
                                    <Col lg={6}>
                                        <div className="form-group">
                                            <label htmlFor="currency">Currency:</label>
                                            {
                                                props.currencylist && props.currency && (
                                                    <Select
                                                        options = {props.currencylist}
                                                        defaultValue={props.currency}
                                                        placeholder='Pilih currency...'
                                                        id='currency'
                                                        name='currency'
                                                        onChange={(e) => {eventOnChange(e, 'currency')}}
                                                        className='select-custom  fw-bold'
                                                        styles={{
                                                            singleValue: (base) => ({
                                                                ...base,
                                                                display: "flex",
                                                                alignItems: "center"
                                                            })
                                                        }}
                                                    />  
                                                )
                                            }    
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                    <div className="form-group">
                                            <label htmlFor="rate">Rate:</label>
                                            <CurrencyInput
                                                id='rate'
                                                name="rate"
                                                placeholder="Please input rate"
                                                className='form-control fw-bold'
                                                prefix=''
                                                decimalSeparator='.'
                                                defaultValue={props.rate || 1}
                                                decimalsLimit={0}
                                                onValueChange={(value)=>eventOnChange(value, 'rate')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <div className="form-group">
                                    <label htmlFor="amount">Amount {defaultCurrency && (<span>({defaultCurrency})</span>)}:</label>
                                    <CurrencyInput
                                        id='amount'
                                        name="amount"
                                        placeholder="Masukan jumlah transaksinya..."
                                        className='form-control fw-bold'
                                        decimalSeparator='.'
                                        defaultValue={0}
                                        decimalsLimit={0}
                                        onValueChange={(value)=>eventOnChange(value, 'amount')}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">Description:</label>
                                    <ReactQuill name="address" theme="snow" onChange={(e)=> eventOnChange(e, 'description')} />
                                    
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={!dataPost.account} type='submit' className='btn btn-primary me-2'>Tambah</Button>
                        <Button className="btn btn-light" onClick={props.onHide}>Batal</Button>
                    </Modal.Footer>
                </form>
        </Modal>
    )
}

export default FormAddJournalComponent;