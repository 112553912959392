import React from 'react';
import Layout from '../../views/layout';
import UserListComponent from '../../components/users/userList';

const UsersPage = () => {
  return (
    <Layout>
        <UserListComponent/>
    </Layout>
  )
}

export default UsersPage;