import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate  } from 'react-router-dom';
import useSWR from 'swr';
import config from '../../config';
import LoadingSpinner from "../global/loadingSpinner";
import * as Utils from '../../features/utils';
import Select from "react-select";

const FormEditCategoryComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [code, setCode] = useState("");   
    const [categoryId, setCategoryId] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(()=> {
        const getCategoryId = async() => {
            try {
                const response = await axios.get(config.server.backend + `/category/${id}`);
                setName(response.data.name);
                setCode(response.data.code);
                setCategoryId(response.data.categoryId);
            } catch (error) {
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
            }
        }
        getCategoryId();
   }, [id]);    

    const updateCategory = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await axios.patch( config.server.backend + `/category/${id}`, {
                name: name,
                code: code,
                categoryId: categoryId,
            }).then(() => {
                navigate('/category');
            }).catch((error) => {
                setIsLoading(false);
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
                Utils.scrollToTop();
            });
        
        } catch (error) {
            setIsLoading(false);
            if(error.response) {
                setMsg(error.response.data.msg);
            }
            Utils.scrollToTop();
        }
    };
    
    const getCategory = async() => {
        const response = await axios.get( config.server.backend + '/category' );
        return response.data;
    };  

    // realtime data pakai SWR
    const {data} = useSWR('categoryData', getCategory);
    if(!data) return <span>Data is empty...</span>;
    console.log(data);

    return (
        <div className='row'>
            
            {
                isLoading && ( <LoadingSpinner /> )
            }
            <div className="col-lg-6 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Update data category</h4>
                        <p className="card-description">Updating the data category here below.</p>

                        {msg &&
                            <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{msg}</div>
                        }
                        <form onSubmit={updateCategory}>
                            <div className="form-group">
                                <label htmlFor="name">Category Name</label>
                                <input name='name' type="text" className="form-control" placeholder='Category Name' value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="code">Category Code</label>
                                <input name='code' type="text" className="form-control" placeholder='Category Code' value={code} onChange={(e) => setCode((e.target.value).toUpperCase())} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="categoryId">Category Parent</label>
                                <Select
                                    options = {
                                        data.map((cat) => (
                                            {
                                                label: cat.name,
                                                value: cat.id,
                                                key: cat.id
                                            }
                                        ))
                                    }
                                    placeholder='Select parent...'
                                    name='categoryId'
                                    onChange={(e) => {setCategoryId(e.value)}}
                                    value={categoryId}
                                    styles={{
                                        singleValue: (base) => ({
                                            ...base,
                                            display: "flex",
                                            alignItems: "center"
                                        })
                                    }}
                                />  
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary me-2">Submit</button>
                                <Link to={`/category/`} className="btn btn-light">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default FormEditCategoryComponent;