
import moment from 'moment-timezone';
export const scrollToTop = () => {
   return window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}

export const onChangeName = (e) => {
    const val = e.target.value;
    const valSplit = (val.toLowerCase()).split(' ').join('-');
    return {
        value: val,
        slug: encodeURI(encodeURI(valSplit))
    };
}

export const currentTime = () => {
    const today = new Date();
    const curHr = today.getHours();
    if (curHr < 11) {
        return 'Pagi';
    } else if (curHr < 15) {
        return 'Siang';
    } else if(curHr >= 15 && curHr < 18) {
        return 'Sore';
    } else {
        return 'Malam';
    }
}

export const getObjectByProp = (object, id, prop = 'id') => {
    let objId = object.findIndex(obj => {
        if(obj[prop] === id) {
            return obj;
        }
        return false;
    });
    return object[objId];
}

export const getIndexById = (object, value) => {
    return object.findIndex(obj_x => obj_x.id === value);
}

export const getIndexOfId = (arr, k) => {
    for (var i = 0; i < arr.length; i++) {
      var index = arr[i].indexOf(k);
      if (index > -1) {
        return [i, index];
      }
    }
  }

export const showDateFormat = (date) => {
    moment.tz.setDefault('Asia/Jakarta');
    const newDateFormat = JSON.stringify(new Date(date).toLocaleDateString('en-EN', {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    })).replace(/(^"|"$)/g, '');
    return newDateFormat;
}

export const listOfMonthOptions = () => {
    return [
        { value: '01', label: 'Januari' },
        { value: '02', label: 'Februari' },
        { value: '03', label: 'Maret' },
        { value: '04', label: 'April' },
        { value: '05', label: 'Mei' },
        { value: '06', label: 'Juni' },
        { value: '07', label: 'Juli' },
        { value: '08', label: 'Agustus' },
        { value: '09', label: 'September' },
        { value: '10', label: 'Oktober' },
        { value: '11', label: 'Nopember' },
        { value: '12', label: 'Desember' }
    ];
}

export const listOfYearOptions = () => {
    let yearOpts = [];
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    for(let a = currentYear + 1; a > currentYear - 11; a--) {
        yearOpts = [...yearOpts, { value: a, label: a }];
    }
    return yearOpts;
}

export const clearCacheData = () => {
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
};

export const currencyFormat = (num) => {
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}