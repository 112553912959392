import { useState } from "react";
import MUIDataTable from "mui-datatables";
import parse from 'html-react-parser';
import { currencyFormat } from '../../features/utils';
import { TableCell } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import FormEditJournalComponent from "./formEditJournal";

const TableJournalComponent = (props) => {
    //console.log("PROPS TABLE JOURNAL: ", props);
    const [rowDataIndex, setRowDataIndex] = useState("");
    const [rootID, setRootID] = useState("");
    const [rootUUID, setRootUUID] = useState("");
    const [editModalShow, setEditModalShow] = useState(false);

    const editRowJournal = (index, id, uuid) => {
        setRowDataIndex(index);
        setRootID(id);
        setRootUUID(uuid);
        setEditModalShow(true)
    }

    const deleteRowJournal = (index, name) => {
        //console.log(index, name)
    }
    const options = {
        download: false,
        print: false,
        filter: false,
        viewColumns: false,
        search: false,
        responsive: 'standard',
        selectableRows: 'none',
        toolbar: 'none',
        setTableProps: () => ({className: "table-journal"})
    };
    const columns = [
        {
            name: 'account',
            label: 'Account',
            options: {
                sort: true,
                setCellHeaderProps: () => { return { width: "180px", style: {
                    fontWeight: "600"
                }} },
                setCellProps: () => { return {style: {
                    backgroundColor: "#F4F5F7",
                }} },
                customBodyRender: (value, tableMeta, updateValue) => (
                    value?.label
                )
            },
        },
        {
            name: 'account',
            label: 'Code',
            options: {
                sort: true,
                setCellHeaderProps: () => { return {  style: {
                    textAlign: "center"
                }} },
                setCellProps: () => { return { width: "100px"} },
                customBodyRender: (value, tableMeta, updateValue) => (
                    value?.code
                )
            },
        },
        {
            name: 'vendor',
            label: 'Vendor',
            options: {
                sort: true,
                setCellProps: () => { return { width: "200px"} },
                customBodyRender: (value, tableMeta, updateValue) => (
                    value?.label
                )
            },
        },
        {
            name: 'description',
            label: 'Description',
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    parse(value)
                )
            },
        },
        {
            name: 'rate',
            label: 'Rate.',
            options: {
                sort: true,
                setCellProps: () => { return { align:"right"} },
                customBodyRender: (value, tableMeta, updateValue) => (
                    currencyFormat(value)
                )
            },
        },
        {
            name: 'amount',
            label: 'Amount',
            options: {
                sort: true,
                setCellProps: () => { return { align:"right"} },
                customBodyRender: (value, tableMeta, updateValue) => (
                    currencyFormat(value)
                )
            },
        },
        {
            name: 'currency',
            label: 'Curr.',
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value?.code
                )
            },
        },
        {
            name: 'index',
            options :{
                customHeadRender : ( columnMeta, handleToggleColumn, sortOrder) => {
                    return (
                        <TableCell key={columnMeta.index}>&nbsp;</TableCell>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <>
                        <div className="btn-group">
                            <button type="button" className="btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </button>
                            <ul className="dropdown-menu">
                                <li><button onClick={() => editRowJournal(value, props?.data.root_id, props?.data.root_uuid)} className='btn btn-link dropdown-item'>Edit</button></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><button onClick={() => deleteRowJournal(value, props?.data.root_id, props?.data.root_uuid)} className='btn btn-link dropdown-item'>Delete</button></li>
                            </ul>
                        </div>
                    </> 
                    }
                
            }
        }
    ];

    return (
        <>
            {
                editModalShow && <FormEditJournalComponent 
                show={editModalShow}
                user={props?.user}
                backdrop="static"
                keyboard={false}
                rowindex={rowDataIndex}
                rootid={rootID}
                rootuuid={rootUUID}
                vendorlist={props?.vendorlist}
                currencylist={props?.currencylist}
                typelist={props?.typelist}
                accountdropdownlist={props?.accountdropdownlist}
                onHide={(e) => {
                        setEditModalShow(false);
                        //setShowTab(false);
                        //getJournalByMonthAndYear(selectedMonth.value, selectedYear.value);
                    }
                }
            />
            }
            
            <div className="table-responsive">
                <MUIDataTable
                    data={props.data.data}
                    columns={columns}
                    options={options}
                />
            </div>
        </>
    )
}

export default TableJournalComponent;