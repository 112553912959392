import React from 'react';
import Layout from '../../views/layout';
import FormAddUserComponent from '../../components/users/formAddUsers';

const AddUserPage = () => {
  return (
    <Layout>
        <FormAddUserComponent/>
    </Layout>
  )
};

export default AddUserPage;