import React, { useState } from 'react';
import {Link } from 'react-router-dom';
import axios from "axios";
import useSWR, {useSWRConfig} from 'swr';
import config from '../../config';
import { confirmAlert } from 'react-confirm-alert'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faRemove, faEllipsisV, faArrowAltCircleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';


const CategoryListComponent = () => {
    const { mutate } = useSWRConfig();
    const [resultData, setResultData] = useState([]);
    
    const getCategory = async() => {
        const response = await axios.get(config.server.backend + '/category');
        const responseData = response.data;
        const resultMapping = mappingData(responseData);
        setResultData(resultMapping);
        return responseData;
    };

    const deleteCategory = (categoryId, titleName) => {
        confirmAlert({
            overlayClassName: 'global-overlay-bg',
            customUI: ({ onClose }) => {
              return (
                <div className='card'>
                    <div className="card-body">
                    <h4 className='card-title'>Are you sure?</h4>
                    <p className='card-description fs-5 text-black mb-4'>You want to delete the <code><strong className='fs-5'>{titleName}</strong></code>, and its derivatives?</p>
                    <button className='btn btn-sm btn-outline-primary' onClick={onClose}><FontAwesomeIcon icon={faRefresh} className='me-2' /> No, please back.</button>
                    <button 
                        className='btn btn-sm btn-outline-primary ms-3'
                        onClick={async() => {
                            // hapus database
                            await axios.delete( `${config.server.backend}/category/${categoryId}` );
                            mutate('dataCategory');
                            onClose();
                        }}
                    ><FontAwesomeIcon icon={faRemove} className='me-2' />
                        Yes, Delete it!
                    </button>
                    </div>
                </div>
              );
            }
        });   
    };

    const mappingData = (data) => {
        var resData = [];
        function mappingChilds(dataChild, level) {
            var resDataChilds = [];
            for(let c=0; c < dataChild.length;c++) {
                var child = dataChild[c];
                resDataChilds =[
                    ...resDataChilds,
                    {
                        id: child.id,
                        uuid: child.uuid,
                        name: child.name,
                        code: child.code,
                        level: level + 1
                    }];
                if(child.childs.length) {
                   var getChilds = mappingChilds(child.childs, level + 1);
                   resDataChilds =[
                    ...resDataChilds,
                   ...getChilds];
                }
            }
            return resDataChilds;
        };
        for(let a = 0; a < data.length; a++) {
            const level = 0;
            var rowData = data[a];
            if(rowData.categoryId === 0) {
                resData = [...resData,
                    {
                        id: rowData.id,
                        uuid: rowData.uuid,
                        name: rowData.name,
                        code: rowData.code,
                        level: level
                    }
                ];
                if(rowData?.childs.length) {
                    resData = [...resData,
                        ...mappingChilds(rowData.childs, level)
                    ];
                }
            } 
        }
        return resData;
    };

    // realtime data pakai SWR
    const {data} = useSWR('dataCategory', getCategory);
    if(!data) return <span>Loading update data...</span>;
    return (
        <div className="grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start">
                        <div>
                            <h4 className="card-title card-title-dash">List Kategori</h4>
                            <p className="card-subtitle card-subtitle-dash">List data dari semua kategori utama</p>
                        </div>
                        <div>
                            <Link to="/category/add/" className='btn btn-primary btn-md text-white mb-0 me-0'><i className="mdi mdi-plus"></i> Tambah kategori</Link>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th colSpan={2}>Nama Kategori</th>
                                <th className='text-center'>Kode Kategori</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    resultData.map( (cat) => { 
                                        const {id, uuid, name, code, level } = cat;
                                        return (
                                            <tr key={id} className={'row-level-' + level}>
                                                {
                                                    level > 0 ? (
                                                        <>  
                                                            <td width={40}><FontAwesomeIcon icon={level > 1 ? faArrowRight : faArrowAltCircleRight } className={'icon-color ps-' + (level*2)} /></td>
                                                            <td className={'text-capitalize ps-' + (level+2)}>{name}</td>
                                                        </>
                                                    ) : (
                                                        <td colSpan={2}  className='text-capitalize'>{name}</td>
                                                    )
                                                }
                                                <td className='text-uppercase text-center fw-bold'>{code}</td>
                                                <td>
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <FontAwesomeIcon icon={faEllipsisV} />
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li> <Link to={`/category/edit/${uuid}/`} className='dropdown-item'>Edit</Link></li>
                                                            <li><hr className="dropdown-divider" /></li>
                                                            <li><button onClick={() => deleteCategory(uuid, name)} className='btn btn-link dropdown-item'>Delete</button></li>
                                                        </ul>
                                                    </div>
                                                    {/* <Link to={`/category/edit/${uuid}/`} className='btn btn-sm btn-inverse-primary'>Edit</Link>
                                                    <button onClick={() => deleteCategory(uuid, name)} className='btn btn-sm btn-inverse-danger is-small is-danger ms-3'>Delete</button> */}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryListComponent;