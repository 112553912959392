import React, {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../config';
import * as Utils from '../../features/utils';
import LoadingSpinner from "../global/loadingSpinner";
import Select from "react-select";
import { Button, Col, Modal, Row} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import CurrencyInput from 'react-currency-input-field';

const FormEditJournalComponent = (props) => { 
    //console.log("Form Edit Journal: ", props)
    const [msg, setMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [defaultCurrency, setDefaultCurrency] = useState("");
    const [mainData, setMainData] = useState("");
    const [parseData, setParseData] = useState("");
    const [dataPost, setDataPost] = useState({
        account: {},
        vendor: {},
        type: {},
        currency:{},
        rate: "",
        amount: "",
        description: ""
        
    });

    const updateRowJournal = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        const extendMainData = mainData;
        const extendParseData = parseData;
        extendParseData[props.rowindex] = dataPost;
        extendMainData['data'] = extendParseData;
        try {
            await axios.patch( config.server.backend + `/journal/${props.rootuuid}`, extendMainData).then(() => {
                setIsLoading(false);
                setMsg("");
                setDataPost({
                    account: {},
                    vendor: {},
                    type: {},
                    currency:{},
                    rate: "",
                    amount: "",
                    description: ""
                    
                });
                props.onHide();
            }).catch((error) => {
                setIsLoading(false);
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
                Utils.scrollToTop();
            });
        
        } catch (error) {
            setIsLoading(false);
            if(error.response) {
                setMsg(error.response.data.msg);
            }
            Utils.scrollToTop();
        }
    };
    
    const eventOnChange = (value, name) => {
        const dataPostExtend = dataPost;
        dataPostExtend[name] = value;
        setDataPost(dataPostExtend);
        // set currency on change
        if(name === 'currency') {
            setDefaultCurrency(value.code);
        }
    }

    useEffect(()=>{
        if(props) {
            if(props?.currency) {
                setDefaultCurrency(props.currency.code);
            }
            if(props?.rootuuid) {
                const getJournal = async(uuid) => {
                    setIsLoading(true);
                    await axios.get( config.server.backend + `/journal/${uuid}` )
                    .then( (response) => { 
                        const responseData = response.data;
                        setMainData(responseData);
                        const journalData = JSON.parse(responseData.data);
                        setParseData(journalData);
                        // mapping data to database
                        const journalRow = journalData[props.rowindex];
                        const dataPostExtend = dataPost;
                        dataPostExtend.account = journalRow.account;
                        dataPostExtend.vendor = journalRow.vendor;
                        dataPostExtend.type = journalRow.type;
                        dataPostExtend.currency = journalRow.currency;
                        dataPostExtend.rate = journalRow.rate;
                        dataPostExtend.amount = journalRow.amount;
                        dataPostExtend.description = journalRow.description;
                        setDataPost(dataPostExtend);
                        setIsLoading(false);
                    })
                    .catch(error=> {
                        console.error(error.message);
                        setIsLoading(false);
                    });
                };
                getJournal(props?.rootuuid);
            }
        }
    }, [props, dataPost])

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
               
                
                    <form id='formAddNewRowJournal' onSubmit={updateRowJournal}>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                            Edit Data Journal
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        { msg && <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{msg}</div> }       
                        {
                            isLoading ? ( <LoadingSpinner /> ) : 
                                <Row>
                                    <Col md={6}>
                                    <div className="form-group">
                                            <label>Account:</label>
                                            {
                                                props.accountdropdownlist && dataPost.account && (
                                                    <Select
                                                        options = {props.accountdropdownlist}
                                                        placeholder='Pilih account...'
                                                        defaultValue={dataPost.account}
                                                        id='account'
                                                        name='account'
                                                        onChange={(e) => {eventOnChange(e, 'account')}}
                                                        className='select-custom  fw-bold'
                                                        styles={{
                                                            singleValue: (base) => ({
                                                                ...base,
                                                                display: "flex",
                                                                alignItems: "center"
                                                            })
                                                        }}
                                                    />  
                                                )
                                            }    
                                        </div>
                                        <div className="form-group">
                                            <label>Type Account:</label>
                                            {
                                                props.typelist && dataPost.type && (
                                                    <Select
                                                        options = {props.typelist}
                                                        defaultValue={dataPost.type}
                                                        placeholder='Pilih type account...'
                                                        id='type'
                                                        name='type'
                                                        onChange={(e) => {eventOnChange(e, 'type')}}
                                                        className='select-custom  fw-bold'
                                                        styles={{
                                                            singleValue: (base) => ({
                                                                ...base,
                                                                display: "flex",
                                                                alignItems: "center"
                                                            })
                                                        }}
                                                    />  
                                                )
                                            }    
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="vendor">Nama Vendor:</label>
                                            {
                                                props.vendorlist && dataPost.vendor && (
                                                    <Select
                                                        options = {props.vendorlist}
                                                        defaultValue={dataPost.vendor}
                                                        placeholder='Pilih vendor...'
                                                        id='vendor'
                                                        name='vendor'
                                                        onChange={(e) => {eventOnChange(e, 'vendor')}}
                                                        className='select-custom  fw-bold'
                                                        styles={{
                                                            singleValue: (base) => ({
                                                                ...base,
                                                                display: "flex",
                                                                alignItems: "center"
                                                            })
                                                        }}
                                                    />  
                                                )
                                            }    
                                        </div>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="form-group">
                                                    <label htmlFor="currency">Currency:</label>
                                                    {
                                                        props.currencylist && dataPost.currency && (
                                                            <Select
                                                                options = {props.currencylist}
                                                                defaultValue={dataPost.currency}
                                                                placeholder='Pilih currency...'
                                                                id='currency'
                                                                name='currency'
                                                                onChange={(e) => {eventOnChange(e, 'currency')}}
                                                                className='select-custom  fw-bold'
                                                                styles={{
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        display: "flex",
                                                                        alignItems: "center"
                                                                    })
                                                                }}
                                                            />  
                                                        )
                                                    }    
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                            <div className="form-group">
                                                    <label htmlFor="rate">Rate:</label>
                                                    <CurrencyInput
                                                        id='rate'
                                                        name="rate"
                                                        placeholder="Please input rate"
                                                        className='form-control fw-bold'
                                                        prefix=''
                                                        decimalSeparator='.'
                                                        defaultValue={dataPost.rate}
                                                        decimalsLimit={0}
                                                        onValueChange={(value)=>eventOnChange(value, 'rate')}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <label htmlFor="amount">Amount {defaultCurrency && (<span>({defaultCurrency})</span>)}:</label>
                                            <CurrencyInput
                                                id='amount'
                                                name="amount"
                                                placeholder="Masukan jumlah transaksinya..."
                                                className='form-control fw-bold'
                                                decimalSeparator='.'
                                                defaultValue={dataPost.amount}
                                                decimalsLimit={0}
                                                onValueChange={(value)=>eventOnChange(value, 'amount')}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description">Description:</label>
                                            <ReactQuill name="address" theme="snow" value={dataPost.description} onChange={(e)=> eventOnChange(e, 'description')} />
                                            
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button disabled={isLoading} type='submit' className='btn btn-primary me-2'>Update</Button>
                            <Button className="btn btn-light" onClick={props.onHide}>Batal</Button>
                        </Modal.Footer>
                    </form>

               
        </Modal>
    )
}

export default FormEditJournalComponent;