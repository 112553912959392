// const config = {
//     server: {
//         backend: "http://localhost:5000",
//         portal: "http://localhost:3000"
//     },
//     currency: 'Rp. '
// }
// export default config;

const config = {
    server: {
        backend: "https://api.itechnosia.com",
        portal: "https://app.itechnosia.com"
    },
    currency: 'Rp. '
}
export default config;