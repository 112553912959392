import React, {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../config';
import LoadingSpinner from "../global/loadingSpinner";
import * as Utils from '../../features/utils';
import { Button, Modal} from 'react-bootstrap';

const FormEditAccountComponent = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [code, setCode] = useState("");   
    const [accountId, setAccountId] = useState("");
    const [msg, setMsg] = useState("");

    useEffect(()=> {
        const getAccountId = async() => {
            try {
                const response = await axios.get(config.server.backend + `/account/${props.uuid}`);
                setName(response.data.name);
                setCode(response.data.code);
                setAccountId(response.data.accountId);
            } catch (error) {
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
            }
        }
        getAccountId();
   }, [props]);    

    const updateAccount = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await axios.patch( config.server.backend + `/account/${props.uuid}`, {
                name: name,
                code: code,
                accountId: accountId,
            }).then(() => {
                setName("");
                setCode("");
                setIsLoading(false);
                props.onHide();
            }).catch((error) => {
                setIsLoading(false);
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
                Utils.scrollToTop();
            });
        
        } catch (error) {
            setIsLoading(false);
            if(error.response) {
                setMsg(error.response.data.msg);
            }
            Utils.scrollToTop();
        }
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <form onSubmit={updateAccount}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        Edit { (props.name).toLowerCase() }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            isLoading && ( <LoadingSpinner /> )
                        }
                        { msg && <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{msg}</div> }
                        <div className="form-group">
                            <label htmlFor="name">Nama Account:</label>
                            <input name='name' type="text" className="form-control" placeholder='Name account...' value={name || ''} onChange={(e) => setName((e.target.value).toLowerCase())} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="code">Kode Account:</label>
                            <input name='code' type="text" className="form-control" placeholder='Kode account...' value={code || ''} onChange={(e) => setCode((e.target.value).toUpperCase())} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='submit' className='btn btn-primary me-2'>Update</Button>
                        <Button className="btn btn-light" onClick={props.onHide}>Cancel</Button>
                    </Modal.Footer>
                </form>
        </Modal>
    )
}

export default FormEditAccountComponent;