import React from 'react';
import Layout from '../../views/layout';
import FormEditVendorComponent from '../../components/vendors/formEditVendor';

const EditVendorPage = () => {

  return (
    <Layout>
        <FormEditVendorComponent/>
    </Layout>
  )
};

export default EditVendorPage;