import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMe, LogOut, reset  } from '../features/authSlice';
import Footer from '../components/global/footer';
import Navbar from '../components/global/navbar';
import Sidebar from '../components/global/sidebar';

const Layout = ({children}) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isError, user } = useSelector(( state => state.auth));
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { user });
    }
    return child;
  });

  useEffect(()=> {
    dispatch(getMe());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if(isError) {
      dispatch(LogOut());
      dispatch(reset());
      navigate('/');
    }else {
      setLoading(false);
    }
  }, [isError, navigate, dispatch]);

  return (
    <React.Fragment>
      { !loading && (
        <>
          <Navbar user={user}/>
          <div className="container-fluid page-body-wrapper pt-0 proBanner-padding-top">
              <Sidebar user={user}/>
              <div className="main-panel">
                  <div className='content-wrapper'>{childrenWithProps}</div>
                  <Footer/>
              </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
};

export default Layout;