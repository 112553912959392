import React from 'react';
import {Link } from 'react-router-dom';
import axios from "axios";
import useSWR, {useSWRConfig} from 'swr';
import config from '../../config';
import { confirmAlert } from 'react-confirm-alert'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faRefresh, faRemove } from '@fortawesome/free-solid-svg-icons';
import MUIDataTable from "mui-datatables";
import { TableCell } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { showDateFormat } from '../../features/utils';


const CompanyListComponent = () => {
    const { mutate } = useSWRConfig();
    
    const getCompany = async() => {
        const response = await axios.get(config.server.backend + '/company');
        return response.data;
    };

    const deleteCompany = (companyId, titleName) => {
        confirmAlert({
            overlayClassName: 'global-overlay-bg',
            customUI: ({ onClose }) => {
              return (
                <div className='card'>
                    <div className="card-body">
                    <h4 className='card-title'>Are you sure?</h4>
                    <p className='card-description'>You will deleting <code><strong>{titleName}</strong></code>?</p>
                    <button className='btn btn-sm btn-inverse-primary' onClick={onClose}><FontAwesomeIcon icon={faRefresh} className='me-2' /> No, please back.</button>
                    <button 
                        className='btn btn-sm btn-inverse-danger ms-3'
                        onClick={async() => {
                            // hapus database
                            await axios.delete( `${config.server.backend}/company/${companyId}` );
                            mutate('dataCompany');
                            onClose();
                        }}
                    ><FontAwesomeIcon icon={faRemove} className='me-2' />
                        Yes, Delete it!
                    </button>
                    </div>
                </div>
              );
            }
        });   
    };

    // realtime data pakai SWR
    const {data} = useSWR('dataCompany', getCompany);
    if(!data) return <span>Loading update data...</span>;

    const columns = [
        {
            name: 'name',
            label: 'Nama Perusahaan',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let logo = data[tableMeta.rowIndex]['logo'];
                    return (
                    <Container>
                        <Row className='g-2 align-items-center'>
                            <Col className='p-0 col-img'>
                                {logo && <img src={config.server.backend + '/' + logo} alt={value} className='img-table' /> }
                            </Col>
                            <Col className='col-auto'>
                                {value}
                            </Col>
                        </Row>
                    </Container>
                    )
                }
            },
        },
        {
            name: 'nib',
            label: 'NIB',
            options: {
                filter: true,
            },
        },
        {
            name: 'npwp',
            label: 'npwp',
            options: {
                filter: true,
            },
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
            },
        },
        {
            name: 'phone',
            label: 'Telepon',
            options: {
                filter: true,
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
            },
        },
        {
            name: 'membership',
            label: 'Keanggotaan',
            options: {
                filter: true,
            },
        },
        {
            name: 'registered',
            label: 'Registrasi',
            options: {
                filter: true,
                customBodyRender : (value, tableMeta, updateValue) => (
                    <>
                        <i className="mdi mdi-calendar text-muted me-1"></i>
                        { showDateFormat(value) }
                    </>
                )
            },
        },
        {
            name: 'uuid',
            options: {
                filter: false,
                sort: false,
                customHeadRender : ( columnMeta, handleToggleColumn, sortOrder) => {
                    return (
                        <TableCell key={columnMeta.index}>&nbsp;</TableCell>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div className="btn-group">
                        <button type="button" className="btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </button>
                        <ul className="dropdown-menu">
                            <li> <Link to={`/company/edit/${value}/`} className='dropdown-item'>Edit</Link></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><button onClick={() => deleteCompany(value, tableMeta.tableData[tableMeta.rowIndex].name)} className='btn btn-link dropdown-item'>Delete</button></li>
                        </ul>
                    </div>
                )
            },
        },
    ];
  
    const options = {
        download: false,
        print: false,
        filter: false,
        viewColumns: false,
        responsive: 'standard',
        selectableRows: 'none',
        setTableProps: () => ({className: "table-companies"})
    };

    return (
        <div className="grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start">
                        <div>
                            <h4 className="card-title card-title-dash">Company List</h4>
                            <p className="card-subtitle card-subtitle-dash">List of all data company</p>
                        </div>
                        <div>
                            <Link to="/company/add/" className='btn btn-primary btn-md text-white mb-0 me-0'><i className="mdi mdi-plus"></i> Add new company</Link>
                        </div>
                    </div>
                    <div className="table-responsive">
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options}
                    />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyListComponent;