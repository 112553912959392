import React from 'react';
import Layout from '../../views/layout';
import AccountListComponent from '../../components/account/accountList';


const AccountListPage = () => {
  return (
    <Layout>
        <AccountListComponent />
    </Layout>
  )
}

export default AccountListPage;