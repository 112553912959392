import React from 'react';
import Layout from '../../views/layout';
import VendorListComponent from '../../components/vendors/vendorList';
const VendorsListPage = () => {
  return (
    <Layout>
        <VendorListComponent/>
    </Layout>
  )
}

export default VendorsListPage;