import React from 'react';
import Layout from '../../views/layout';
import LayoutBlank from '../../views/layoutBlank';
import SumexListComponent from '../../components/sumex/sumexList';
import { useSelector} from 'react-redux';

const SumexListPage = () => {
  const { user } = useSelector(( state => state.auth));
  return (
    <Layout>
      {
        user && user.role === "admin" ? <SumexListComponent /> : <LayoutBlank/>
      }
    </Layout>
  )
};

export default SumexListPage;