import React from 'react';
import Layout from '../../views/layout';
import FormCreateJournalComponent from '../../components/journal/formCreateJournal';


const JournalCreatePage = () => {
  return (
    <Layout>
        <FormCreateJournalComponent />
    </Layout>
  )
}

export default JournalCreatePage;