import React, {useEffect, useState} from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { Link, useParams, useNavigate } from 'react-router-dom';
import config from '../../config';
import LoadingSpinner from "../global/loadingSpinner";
import ReactQuill from 'react-quill';
import Select from "react-select";
import { Row, Col } from "react-bootstrap";

const FormEditVendorComponent = (props) => {
    const id = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [nib, setNib] = useState('');
    const [npwp, setNpwp] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [pic, setPic] = useState('');
    const [address, setAddress] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [vendorTypeId, setVendorTypeId] = useState([]);
    const [dataVendorType, setDataVendorType] = useState([]);
    const [defaultVendorType, setDefaultVendorType] = useState('')

    useEffect(()=> {
        const getVendorById = async() => {
            try {
                const response = await axios.get(config.server.backend + `/vendor/${id.id}`);
                const resData = response.data;
                const { name, nib, npwp, email, phone, code,  pic, address, companyId, company, vendortypes } = resData;
                setCode(code);
                setName(name);
                setNib(nib);
                setNpwp(npwp);
                setEmail(email);
                setPic(pic);
                setPhone(phone);
                setAddress(address);
                setCompanyId(companyId);
                setCompanyName(company.name);
                console.log(vendortypes);
                let defaultTypeMap = [];
                vendortypes.map(ven => (
                    defaultTypeMap.push({
                        label: ven.name,
                        value: ven.id,
                        key: ven.id
                    })
                ))
                setDefaultVendorType(defaultTypeMap);
            } catch (error) {
                if(error.response) {
                    setMsg(error.response.data.msg);
                }
            }
            setIsLoading(false);
        };
        getVendorById(); 
   }, [id]);  

    const updateVendor = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        let newDataPost = {                              
            name: name,
            nib: nib,
            npwp: npwp,
            email: email,
            code: code,
            phone: phone,
            pic: pic,
            address: address,
            companyId: companyId,
            vendorTypeId: vendorTypeId
        }

        try {                    
            await axios.patch( config.server.backend + `/vendor/${id.id}`, newDataPost).then(() => {
                setIsLoading(false);
                navigate('/vendor');
            });
        } catch (error) {
            setIsLoading(false);
            if(error.response) {
                setMsg(error.response.data.msg);
            }
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
        }
    }; 

    // get data vendor type with SWR
    const getDataVendorType = async(url) => {
        const response = await axios.get( url);
        const responseData = response.data;
        let objVendoreType = [];
        responseData.forEach(element => {
            objVendoreType = [...objVendoreType,
                {
                    label: element.name,
                    value: element.id,
                    key: element.id,
                }
            ]
        });
        setDataVendorType(objVendoreType);
    };  
    useSWR( config.server.backend + '/vendor-type' , getDataVendorType);

    return (
        <Row>
            {msg &&
                <div className="shadow-sm p-3 mb-5 bg-body-tertiary rounded text-center fw-bold text-danger p-4 text-danger">{msg}</div>
            }
            {
                isLoading && ( <LoadingSpinner /> )
            }
            <Col xl={8} xxl={6} className='grid-margin stretch-card'>
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Edit Data Vendor</h4>
                        <p className="card-description">Form untuk merubah data vendor.</p>
                        <form onSubmit={updateVendor}>
                            <Row>
                                <Col xl={6}>
                                    {
                                        props?.user && props.user.role === 'admin' && (
                                            <div className="form-group">
                                                <label htmlFor="companyName">Vendor dari Perusahaan:</label>
                                                <p className='bg-body-secondary text-uppercase border rounded-1 px-3 py-1 fw-bold'>{companyName}</p>
                                            </div>
                                        )
                                    }
                                    <div className="form-group">
                                        <label htmlFor="name">Nama Vendor:</label>
                                        <input name='name' type="text" className="form-control" placeholder='Nama vendor...' value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="code">Kode Vendor:</label>
                                        <input name='code' type="text" className="form-control" placeholder='Kode vendor...' value={code} onChange={(e) => setCode(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="vendorType">Tipe Vendor:</label>
                                        {
                                            dataVendorType && defaultVendorType && (
                                                <Select
                                                    options = {dataVendorType}
                                                    defaultValue={defaultVendorType}
                                                    placeholder='Pilih tipe vendor...'
                                                    name='vendorTypeId'
                                                    onChange={(e) => {setVendorTypeId(e)}}
                                                    className='select-custom'
                                                    isMulti={true}
                                                    styles={{
                                                        singleValue: (base) => ({
                                                            ...base,
                                                            display: "flex",
                                                            alignItems: "center"
                                                        })
                                                    }}
                                                />  
                                            )
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nib">NIB:</label>
                                        <input name='nib' type="text" className="form-control" placeholder='NIB...' value={nib} onChange={(e) => setNib(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="npwp">NPWP:</label>
                                        <input name='npwp' type="text" className="form-control" placeholder='NPWP...' value={npwp} onChange={(e) => setNpwp(e.target.value)} />
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <div className="form-group">
                                        <label htmlFor="email">Email:</label>
                                        <input name='email' type="text" className="form-control" placeholder='Email...' value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">No. Telepon:</label>
                                        <input name='phone' type="text" className="form-control" placeholder='Telepon...' value={phone || ''} onChange={(e) => setPhone(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="pic">PIC:</label>
                                        <input name='pic' type="text" className="form-control" placeholder='Person in charge...' value={pic} onChange={(e) => setPic(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address">Alamat:</label>
                                        <ReactQuill name="address" theme="snow" value={address} onChange={(e)=> setAddress(e)} />
                                    </div>
                                </Col>
                            </Row>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary me-2">Update</button>
                                <Link to={`/vendor/`} className="btn btn-light">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default FormEditVendorComponent;