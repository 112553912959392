import React, { useEffect, useState } from 'react';
import axios from "axios";
import useSWR, {mutate} from 'swr';
import config from '../../config';
import { confirmAlert } from 'react-confirm-alert'; 
import { faRefresh, faRemove, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faFolderOpen, faFileAlt, faFile } from '@fortawesome/free-regular-svg-icons';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormAddAccountComponent from './formAddAccount';
import FormEditAccountComponent from './formEditAccount';


const AccountListComponent = (props) => {
    const [resultData, setResultData] = useState([]);
    const [addModalShow, setAddModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [parentStatus, setParentStatus] = useState(0);
    const [uuid, setUuid] = useState("");
    const [name, setName] = useState("");

    const showFormAddModal = (status) => {
        setParentStatus(status);
        setAddModalShow(true);
    }

    const showFormEditModal = (uuid, name) => {
        setUuid(uuid);
        setName(name);
        setEditModalShow(true);
    }

    const deleteAccount = (accountId, titleName) => {
        confirmAlert({
            overlayClassName: 'global-overlay-bg',
            customUI: ({ onClose }) => {
              return (
                <div className='card'>
                    <div className="card-body">
                    <h4 className='card-title'>Are you sure?</h4>
                    <p className='card-description fs-5 text-black mb-4'>You want to delete the <code><strong className='fs-5'>{titleName}</strong></code>, and its derivatives?</p>
                    <button className='btn btn-sm btn-outline-primary' onClick={onClose}><FontAwesomeIcon icon={faRefresh} className='me-2' /> No, please back.</button>
                    <button 
                        className='btn btn-sm btn-outline-primary ms-3'
                        onClick={async() => {
                            // hapus database
                            await axios.delete( `${config.server.backend}/account/${accountId}` );
                            mutate('dataAccount');
                            onClose();
                        }}
                    ><FontAwesomeIcon icon={faRemove} className='me-2' />
                        Yes, Delete it!
                    </button>
                    </div>
                </div>
              );
            }
        });   
    };

    const mappingData = (data) => {
        var resData = [];
        function mappingChilds(dataChild, level) {
            var resDataChilds = [];
            if(dataChild) {
                for(let c=0; c < dataChild.length;c++) {
                    var child = dataChild[c];
                    resDataChilds =[
                        ...resDataChilds,
                        {
                            id: child.id,
                            uuid: child.uuid,
                            name: child.name,
                            code: child.code,
                            level: level + 1
                        }];
                    if(child.childs) {
                    var getChilds = mappingChilds(child.childs, level + 1);
                    resDataChilds =[
                        ...resDataChilds,
                    ...getChilds];
                    }
                }
            }
            return resDataChilds;
        };
        if(data) {
            for(let a = 0; a < data.length; a++) {
                const level = 0;
                var rowData = data[a];
                if(rowData.accountId === 0) {
                    resData = [...resData,
                        {
                            id: rowData.id,
                            uuid: rowData.uuid,
                            name: rowData.name,
                            code: rowData.code,
                            level: level
                        }
                    ];
                    if(rowData?.childs.length) {
                        resData = [...resData,
                            ...mappingChilds(rowData.childs, level)
                        ];
                    }
                } 
            }
        }
        return resData;
    };

    // realtime data pakai SWR
    const getAccount = async() => {
        if(props.user?.company) {
            let accountEndpoint = props.user.role === 'admin' ? '/account/' : '/account-company/' + props.user.company.uuid ;
            const response = await axios.get(config.server.backend + accountEndpoint);
            return(response.data);
        } else {
            return false;
        }
    };
    
    // realtime data pakai SWR
    const {data} = useSWR('dataAccount', getAccount);
    mutate('dataAccount');

    console.log("Datanya:", data);

    useEffect(()=> {
        //const resultMapping = mappingData(data);
        setResultData(data);
    }, [data]);

    const setAcctions = (id, uuid, name, level) => {
        return (
            <div className="btn-group">
                <button type="button" className="btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                    <FontAwesomeIcon icon={faEllipsisV} />
                </button>
                <ul className="dropdown-menu">
                    {
                        level < 9 && (
                            <>
                                
                    <li><button onClick={() => showFormAddModal(id)} className='btn btn-link dropdown-item'>Create Child</button></li>
                    <li><hr className="dropdown-divider" /></li>
                            </>
                        )
                    }
                    <li><button onClick={() => showFormEditModal(uuid, name)} className='btn btn-link dropdown-item'>Edit</button></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><button onClick={() => deleteAccount(uuid, name)} className='btn btn-link dropdown-item'>Delete</button></li>
                </ul>
            </div>
        )
    }

    const breakDownData =  (listData, level) => {
        let listChild = listData.map( children => {
            const {id, uuid, name, code, childs } = children;
            let childStatus = childs && childs.length ? true : false;
            let subChilds =   <li key={id} className='w-100'>
                                <Row className={ childStatus ? 'has-bg g-0' : 'g-0' }>
                                    <Col className='col-code'>{
                                        childStatus ? <FontAwesomeIcon className='icon' icon={faFolderOpen}/> : <FontAwesomeIcon className='icon' icon={faFile}/>
                                        } {code}
                                    </Col>
                                    <Col className='col-name'>{name}</Col>
                                    <Col className='col-actions'>{setAcctions(id, uuid, name, level+1)}</Col>
                                </Row>
                                {
                                    childStatus ? (
                                        <ul className='list-breakdown' data-level={level+1}>
                                            {breakDownData(childs, level+1)}
                                        </ul>
                                    ) : ''
                                }
                            </li>;
            return subChilds;
        })
        return listChild;
    }
    return (
        <>  
            <FormAddAccountComponent
                user={props.user}
                parent={parentStatus}
                show={addModalShow}
                onHide={() => {
                    setAddModalShow(false);
                        mutate('dataAccount');
                    }
                }
                backdrop="static"
                keyboard={false}
            />

            <FormEditAccountComponent 
                user={props.user}
                uuid={uuid}
                name={name}
                show={editModalShow}
                onHide={() => {
                    setEditModalShow(false);
                        mutate('dataAccount');
                    }
                }
                backdrop="static"
                keyboard={false}
            />
            
            <div className="grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="d-sm-flex justify-content-between align-items-start">
                            <div>
                                <h4 className="card-title card-title-dash">List Account</h4>
                                <p className="card-subtitle card-subtitle-dash">List data dari semua account utama</p>
                            </div>
                            <div>
                            <Button className='btn btn-primary btn-md text-white mb-0 me-0' variant="primary" onClick={() => showFormAddModal(0)}>
                                <i className="mdi mdi-plus"></i> Tambah account
                            </Button>
                            </div>
                        </div>
                        <ul className='list-breakdown'>
                            { resultData && breakDownData(resultData, 0) }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountListComponent;