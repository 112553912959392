import React from 'react';
import Layout from '../../views/layout';
import LayoutBlank from '../../views/layoutBlank';
import FormAddCompanyComponent from '../../components/companies/formAddCompany';
import { useSelector} from 'react-redux';

const AddCompanyPage = () => {
  const { user } = useSelector(( state => state.auth));
  return (
    <Layout>
      {
        user && user.role === "admin" ? <FormAddCompanyComponent /> : <LayoutBlank/>
      }
    </Layout>
  )
};

export default AddCompanyPage;