import React from 'react';
import {Link } from 'react-router-dom';
import axios from "axios";
import useSWR, {useSWRConfig} from 'swr';
import config from '../../config';
import { confirmAlert } from 'react-confirm-alert'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faRefresh, faRemove } from '@fortawesome/free-solid-svg-icons';


const CurrencyListComponent = () => {
    const { mutate } = useSWRConfig();
    
    const getCurrency = async() => {
        const response = await axios.get(config.server.backend + '/currency');
        return response.data;
    };

    const deleteCurrency = (currencyId, titleName) => {
        confirmAlert({
            overlayClassName: 'global-overlay-bg',
            customUI: ({ onClose }) => {
              return (
                <div className='card'>
                    <div className="card-body">
                    <h4 className='card-title'>Are you sure?</h4>
                    <p className='card-description'>You will deleting <code><strong>{titleName}</strong></code>?</p>
                    <button className='btn btn-sm btn-inverse-primary' onClick={onClose}><FontAwesomeIcon icon={faRefresh} className='me-2' /> No, please back.</button>
                    <button 
                        className='btn btn-sm btn-inverse-danger ms-3'
                        onClick={async() => {
                            // hapus database
                            await axios.delete( `${config.server.backend}/currency/${currencyId}` );
                            mutate('dataCurrency');
                            onClose();
                        }}
                    ><FontAwesomeIcon icon={faRemove} className='me-2' />
                        Yes, Delete it!
                    </button>
                    </div>
                </div>
              );
            }
        });   
    };

    // realtime data pakai SWR
    const {data} = useSWR('dataCurrency', getCurrency);
    if(!data) return <span>Loading update data...</span>;

    return (
        <div className="grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start">
                        <div>
                            <h4 className="card-title card-title-dash">Currency List</h4>
                            <p className="card-subtitle card-subtitle-dash">List of all data currency</p>
                        </div>
                        <div>
                            <Link to="/currency/add/" className='btn btn-primary btn-md text-white mb-0 me-0'><i className="mdi mdi-plus"></i> Add new currency</Link>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Currency Name</th>
                                <th className='text-center'>Currency Code</th>
                                <th className='text-center'>Currency Symbol</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((currency, index) => (
                                <tr key={currency.uuid}>
                                    <td>{index+1}</td>
                                    <td className='text-capitalize'>{currency.name}</td>
                                    <td className='text-uppercase  text-center'>{currency.code}</td>
                                    <td className='text-uppercase text-center'>{currency.symbol}</td>
                                    <td>
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon icon={faEllipsisV} />
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li> <Link to={`/currency/edit/${currency.uuid}/`} className='dropdown-item'>Edit</Link></li>
                                                <li><hr className="dropdown-divider" /></li>
                                                <li><button onClick={() => deleteCurrency(currency.uuid, currency.name)} className='btn btn-link dropdown-item'>Delete</button></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrencyListComponent;