import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = (props) => {
    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                <li className="nav-item">
                    <NavLink className="nav-link" to={"/dashboard/"}>
                    <i className="mdi mdi-apps menu-icon"></i>
                    <span className="menu-title">Dashboard</span></NavLink>
                </li>
                <li className="nav-item nav-category">Journal</li>
                <li className="nav-item">
                    <NavLink className="nav-link" to={"/journal/"}>
                    <i className="mdi mdi-layers-outline menu-icon"></i>
                    <span className="menu-title">Buku Besar</span></NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to={"/journal-create/"}>
                    <i className="mdi mdi-layers-outline menu-icon"></i>
                    <span className="menu-title">Create Journal</span></NavLink>
                </li>
                {/* <li className="nav-item">
                    <NavLink className="nav-link" to={"/category/"}>
                    <i className="mdi mdi-layers-outline menu-icon"></i>
                    <span className="menu-title">Category</span></NavLink>
                </li> */}
                
                <li className="nav-item nav-category">Master Data</li>
                
                <li className="nav-item">
                    <NavLink className="nav-link" to={"/account/"}>
                    <i className="mdi mdi-layers-outline menu-icon"></i>
                    <span className="menu-title">Data Account</span></NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to={"/vendor/"}>
                    <i className="mdi mdi-layers-outline menu-icon"></i>
                    <span className="menu-title">Data Vendor</span></NavLink>
                </li>
                <li className="nav-item nav-category">Settings</li>
                
                {/* <li className="nav-item">
                    <NavLink className="nav-link" to={"/setting/add/"}>
                    <i className="mdi mdi-anchor menu-icon"></i>
                    <span className="menu-title">Setting</span></NavLink>
                </li> */}
                <li className="nav-item">
                    <NavLink className="nav-link"  to={"/users/"}>
                        <i className="menu-icon mdi mdi-account-circle-outline"></i>
                        <span className='menu-title'> Users</span>
                    </NavLink>
                </li>
                {
                    props?.user && props?.user.role !== 'admin' && (
                        <li className="nav-item">
                            <NavLink className="nav-link" to={ props?.user && `/company/edit/${props.user.company.uuid}/`}>
                            <i className="mdi mdi-apple-safari menu-icon"></i>
                            <span className="menu-title">Profil Perusahaan</span></NavLink>
                        </li>
                    )
                }
                {
                    props?.user && props?.user.role === 'admin' && (
                        <>  
                            <li className="nav-item">
                                <NavLink className="nav-link" to={"/company/"}>
                                <i className="mdi mdi-file-document menu-icon"></i>
                                <span className="menu-title">Data Perusahaan</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={"/vendor-type/"}>
                                <i className="mdi mdi-file-document menu-icon"></i>
                                <span className="menu-title">Tipe Vendor </span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={"/types/"}>
                                <i className="mdi mdi-file-document menu-icon"></i>
                                <span className="menu-title">Types</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={"/sumex/"}>
                                <i className="mdi mdi-file-document menu-icon"></i>
                                <span className="menu-title">Sumex</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={"/currency/"}>
                                <i className="mdi mdi-file-document menu-icon"></i>
                                <span className="menu-title">Currencies</span></NavLink>
                            </li>
                        </>
                    )
                }

                {/* <li className="nav-item nav-category">UI Elements</li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                        <i className="menu-icon mdi mdi-floor-plan"></i>
                        <span className="menu-title">UI Elements</span>
                        <i className="menu-arrow"></i>
                    </a>
                <div className="collapse" id="ui-basic">
                    <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="../pages/ui-features/buttons.html">Buttons</a></li>
                    <li className="nav-item"> <a className="nav-link" href="../pages/ui-features/dropdowns.html">Dropdowns</a></li>
                    <li className="nav-item"> <a className="nav-link" href="../pages/ui-features/typography.html">Typography</a></li>
                    </ul>
                </div>
                </li>
                <li className="nav-item nav-category">Forms and Datas</li>
                <li className="nav-item">
                <a className="nav-link" data-bs-toggle="collapse" href="#form-elements" aria-expanded="false"
                    aria-controls="form-elements">
                    <i className="menu-icon mdi mdi-card-text-outline"></i>
                    <span className="menu-title">Form elements</span>
                    <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="form-elements">
                    <ul className="nav flex-column sub-menu">
                    <li className="nav-item"><a className="nav-link" href="../pages/forms/basic_elements.html">Basic Elements</a></li>
                    </ul>
                </div>
                </li>
                <li className="nav-item">
                <a className="nav-link" data-bs-toggle="collapse" href="#charts" aria-expanded="false" aria-controls="charts">
                    <i className="menu-icon mdi mdi-chart-line"></i>
                    <span className="menu-title">Charts</span>
                    <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="charts">
                    <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="../pages/charts/chartjs.html">ChartJs</a></li>
                    </ul>
                </div>
                </li>
                <li className="nav-item">
                <a className="nav-link" data-bs-toggle="collapse" href="#tables" aria-expanded="false" aria-controls="tables">
                    <i className="menu-icon mdi mdi-table"></i>
                    <span className="menu-title">Tables</span>
                    <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="tables">
                    <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="../pages/tables/basic-table.html">Basic table</a></li>
                    </ul>
                </div>
                </li>
                <li className="nav-item">
                <a className="nav-link" data-bs-toggle="collapse" href="#icons" aria-expanded="false" aria-controls="icons">
                    <i className="menu-icon mdi mdi-layers-outline"></i>
                    <span className="menu-title">Icons</span>
                    <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="icons">
                    <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <a className="nav-link" href="../pages/icons/mdi.html">Mdi icons</a></li>
                    </ul>
                </div>
                </li> */}
            </ul>
        </nav>
    );
}

export default Sidebar