import React from 'react';
import Layout from '../../views/layout';
import FormEditCompanyComponent from '../../components/companies/formEditCompany';

const EditCompanyPage = () => {
  return (
    <Layout>
        <FormEditCompanyComponent/>
    </Layout>
  )
};

export default EditCompanyPage;